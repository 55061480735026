import React from "react";
import '../css/default.css';
import { getItem, setItem }    from '../Storage';
import { Button, Text, Input } from '../components';
import UserController          from '../controllers/userController';
import AuthController          from '../controllers/authController';
import ConsultationController  from '../controllers/consultationController';

export default class Feedback extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading_submit_feedback: false,
      feedback_data: {
        satisfied: 0,
        recommend: 0,
        better: '',
      },
    };
  }

  componentDidMount() {

  }

  render_ten_point_rating = (key) => {
    let numbers      = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    let selected_bg  = '';

    let number_btns = numbers.map((number) => {
      let is_selected = this.state.feedback_data[key] === number;
      let text_color  = is_selected ? 'white' : '#141415'
      let background  = is_selected ? '' : 'white';
      return <div className='universal-button gradient-background'
                  key={number}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderRadius: 10, height: 40, flex: 1, marginRight: 1, marginLeft: 1, borderColor: is_selected ? 'white' : '#CFD0D7', background: background }}
                  onClick={ () => {
                    let new_feedback_obj = Object.assign({}, this.state.feedback_data);
                    new_feedback_obj[key] = number;
                    this.setState({ ...this.state, feedback_data: new_feedback_obj });
                  }}>
        <Text style={{ fontWeight: 'bold', color: text_color, fontSize: 14 }}>{ number }</Text>
      </div>
    })

    return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      { number_btns }
    </div>
  }

  render_satisfied_question = () => {

    return <div style={{ marginBottom: 20 }}>
      <Text style={{ fontSize: 14, color: '#6F767E', fontWeight: 'bold' }}>How satisfied are you with your care session?</Text>
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
        { this.render_ten_point_rating('satisfied') }
      </div>
    </div>
  }

  render_recommend_section = () => {
    return <div style={{ marginBottom: 20 }}>
      <Text style={{ fontSize: 14, color: '#6F767E', fontWeight: 'bold' }}>How likely would you be to recommend this service?</Text>
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
        { this.render_ten_point_rating('recommend') }
      </div>
    </div>
  }

  render_better_section = () => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text style={{ fontSize: 14, color: '#6F767E', fontWeight: 'bold', marginBottom: 10 }}>How was your experience today? We’d love to hear your feedback.</Text>

      <textarea className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"
                value={this.state.text}
                style={{ flex: 1, height: 100, fontSize: 15 }}
                onChange={ (event) => {
                  let response_text    = event && event.target && event.target.value ? event.target.value : '';
                  let new_feedback_obj = Object.assign({}, this.state.feedback_data);
                  new_feedback_obj['better'] = response_text;
                  this.setState({ ...this.state, feedback_data: new_feedback_obj });
                }} />

    </div>
  }

  render_buttons = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, alignItems: 'center', marginBottom: 40 }}>

      <Button title='Submit Feedback'
              style={{ height: 55, width: 300, marginBottom: 5, boxShadow: 'none' }}
              titleStyle={{ fontSize: 14 }}
              loading={this.state.loading_submit_feedback}
              onClick={ async () => {
                this.setState({ loading_submit_feedback: true });

                let care_consultation_id = getItem('care_consultation_id');
                let request_object       = {
                  care_consultation_id: care_consultation_id,
                  feedback_data: this.state.feedback_data,
                }

                if (care_consultation_id) {
                  let feedback_data_response = await ConsultationController.submitCareConsultationFeedback(request_object);
                }

                this.setState({ loading_submit_feedback: false });
                if (this.props.skip_feedback_action) {
                  this.props.skip_feedback_action();
                }
              }}/>

      <Button title='Skip'
              grey={true}
              style={{ height: 55, width: 300 }}
              titleStyle={{ fontSize: 14 }}
              onClick={ async () => {
                if (this.props.skip_feedback_action) {
                  this.props.skip_feedback_action();
                }
              }}/>
    </div>
  }

  render_feedback_section = () => {

    return <div className='flex-container-column'>
      { this.render_satisfied_question() }
      { this.render_recommend_section()  }
      { this.render_better_section()     }
      { this.render_buttons()            }
    </div>
  }


  render() {
    return (
      <div style={{  }}>
        { this.render_feedback_section() }
      </div>
    );
  }

}
