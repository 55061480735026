import React, { Component } from 'react';
import { Text } from '../components';

class Error extends Component {

  render() {
    let style = this.props.style;
    let error = this.props.error || '';

    if (!error) {
      return null;
    }

    return <div style={{ borderRadius: 8, backgroundColor: '#FF495C', padding: 12, paddingTop: 5, paddingBottom: 7, maxWidth: 300, alignSelf: 'center', ...style }}>
      <Text style={{ fontWeight: 'bold', color: 'white', fontSize: 14 }}>{ error }</Text>
    </div>
  }

}

export default Error
