import React from 'react';
import UtilitiesController from './utilitiesController';
import AuthController from './authController';


export default class ActionController {

  static exceptionWrapper = (fn) => async (data) => {
      try {
          return await fn(data).catch((err) => {
              throw err;
          });
      } catch (err) {
          return {
              success: false,
              error: err.message
              }
      }
  };

  static actionFunction = ActionController.exceptionWrapper(async (data) => {
      let response  = await UtilitiesController.post('/v5/example_url', data, true);

      return response
  });

}
