import React      from 'react';
import { config } from '../config/index';
import { getItem, setItem } from '../Storage';
import UtilitiesController  from './utilitiesController';

export default class AuthController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.message
            }
        }
    };

    static signInSignOut = AuthController.exceptionWrapper(async (data) => {

        return await UtilitiesController.post('/v4/signup_signin', data, true);
    })

    static signInSignOut_Verify = AuthController.exceptionWrapper(async (data) => {

        return await UtilitiesController.post('/v4/signup_signin_verify', data, true);
    })

    static getLoggedInUser = AuthController.exceptionWrapper(async () => {
        let response     = await UtilitiesController.get(`/v4/api/user`, {}, true);
        let user_details = response && response.success && response.data ? response.data : {};

        if (user_details && user_details._id) {
          setItem('user', user_details);
        }

        return user_details;
    });

    static signOut = AuthController.exceptionWrapper(async () => {
        await AuthController.clearToken();
    });

    static sendPhoneCode = AuthController.exceptionWrapper(async (data) => {
        let login_data = {
            ...data,
            partner: config.SELECTED_PARTNER,
            partner_name: 'DodoVet'
        }
        return await UtilitiesController.post('/v4/send_login_code', login_data, true);
    })

    static validatePhoneCode = AuthController.exceptionWrapper(async (data) => {
        data = {
            ...data,
            partner: config.SELECTED_PARTNER
        }

        let response = await UtilitiesController.post('/v4/verify_login_code', data, true);

        if(response && response.success && response.data) {
            let user = response.data.user;
            user = {
                ...user,
                token: response.data.token
            }
            await AuthController.setUser(user);
        }

        return response
    });

    static sendRegisterPhoneCode = AuthController.exceptionWrapper(async (data) => {
      let phone_code_data = {
        ...data,
        partner_name: 'DodoVet'
      }
        return await UtilitiesController.post('/v4/send_phone_code', phone_code_data, true);
    });

    static validateRegisterPhoneCode = AuthController.exceptionWrapper(async (data) => {
        let response = await UtilitiesController.post('/v4/validate_phone_code', data, true);

        if(response.success && response.data) {
            await AuthController.setUser(response.data);
        }

        return response
    });

    static completeRegistration = AuthController.exceptionWrapper(async (data) => {
        let response = await UtilitiesController.post('/v4/complete_registration', data, true);

        if(response.success && response.data) {
            await AuthController.setUser(response.data);
        }

        return response;
    });

    static loginWithPassword = AuthController.exceptionWrapper(async (data) => {

        let response = await UtilitiesController.post('/login', data, true);

        if(response.success) {
            await AuthController.setUser(response);
        }

        return response;
    });

    static setUser = async (user) => {

    }

    static getUser = async (get_new) => {
        if(get_new) {
            await AuthController.getLoggedInUser();
        }

        let user = {

        }

        return user;
    }
}
