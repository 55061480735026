import React from "react";
import { getItem, setItem } from '../Storage';
import { Button, Text }     from '../components';
import '../css/default.css';

export default class Header extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render_back_button = () => {
    if (this.props.display_back_button) {
      return <div style={{ width: 60, height: 60, backgroundColor: 'yellow', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ backgroundColor: 'white', height: 40, width: 40 }}>{ '<' }</div>
      </div>
    } else {
      return <div style={{ width: 60, height: 60 }}></div>
    }
  }

  render_logo = () => {
    let partner  = getItem('partner');
    let logo_url = partner && partner.logo_url ? partner.logo_url : '';
    return <div className='shadow-[0_10px_60px_1px_rgba(0,0,0,0.1)]' style={{ backgroundColor: 'white', padding: 25, paddingBottom: 10, paddingTop: 10, borderBottomRightRadius: 15, borderBottomLeftRadius: 15, borderWidth: 1, borderStyle: 'solid', borderColor: '#f5f5f5' }}>
      <img src={ 'https://teletailstest.s3.amazonaws.com/profile/1628003122358.png' } width="160" height="50" />
    </div>
  }

  render() {

    if (this.props.hide) {
      return null;
    }

    let partner    = getItem('partner');
    let logo_url   = partner && partner.logo_url ? partner.logo_url : 'https://teletailstest.s3.amazonaws.com/profile/1633529478005.png';
    let title      = this.props.title;
    let subtitle   = this.props.subtitle;
    let logo_width = this.get_logo_width(partner);

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
      <img src={ logo_url } width={logo_width} height="auto" />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <Text style={{ fontWeight: '800', fontSize: 24, color: '#1A1D1F' }}>{ title }</Text>
        { subtitle ? <Text style={{ fontSize: 14, marginTop: 5, marginBottom: 15, color: '#1A1D1F', fontWeight: 'bold' }}>{ subtitle }</Text> : null }
      </div>
    </div>

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        { this.render_back_button () }
        { this.render_logo()         }
        <div style={{ width: 60, height: 60 }}></div>
      </div>
    );
  }

  get_logo_width = (partner) => {
    let partner_name = partner && partner.name ? partner.name : 'teletails';
    let logo_width   = 120;
    switch (partner_name) {
      case 'teletails':
        logo_width = 120;
        break;
      case 'hills':
        logo_width = 80;
        break;
      default:
        logo_width = 120;
    }
    return logo_width;
  }
}
