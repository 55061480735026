import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Screen, Text, Spinner, Error, Loading } from '../components';
import { IntroSlides, Header, SignIn, CareSelection, PhoneAdd } from '../containers';
import { DateUtils, StringUtils, StyleUtils } from '../utils';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import AuthController         from '../controllers/authController';
import UtilitiesController    from '../controllers/utilitiesController';
import ConsultationController from '../controllers/consultationController';

export default class VideoAvailabilityScreen extends React.Component {

  constructor(props) {
    super(props);

    let today_date_str = new Date().toString();
        today_date_str = this.get_cleaned_time_str(today_date_str);

    this.state = {
      display_scheduled: false,
      selected_date_str: today_date_str,
      selected_day_slots: [],
      selection_display_dates: [],
      user: {},
      error: '',
      partner: {},
      loading_slot_id: '',
      selected_slot: null,
      phone_requirement_disabled: false,
      loading_confirm_appointment: false,
      loading_screen: false,
      available_dots_dict: {}
    };
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true })

    let user              = getItem('user');
    let partner           = getItem('partner');
    let display_scheduled = getItem('is_video_scheduled');
    let display_dates     = this.get_display_dates_array();
    let phone_req_disbld  = partner && partner.phone_requirement_disabled && partner.phone_requirement_disabled === true ? true : false;

    this.pull_available_slots_for_date(this.state.selected_date_str);
    this.pull_availabilities_for_dots();

    await DateUtils.delay(2600)

    this.setState({ user: user, partner: partner, selection_display_dates: display_dates, display_scheduled: display_scheduled, loading_screen: false, phone_requirement_disabled: phone_req_disbld });
  }

  render_phone_input = () => {
    return <div>
      <PhoneAdd user={this.state.user}
                partner={this.state.partner}
                success_action={ () => {
                  let user = getItem('user');
                  this.setState({ user: user });
                }} />
    </div>
  }

  render_top_header = (hide) => {
    if (hide) {
      return null;
    }

    let slot_selected = this.state.selected_slot && this.state.selected_slot._id ? true : false;
    let title_text    = slot_selected ? 'Appointment Time' : 'Book video session';
    let subtitle_text = slot_selected ? 'Here are the details of your Video Appointment' : 'Select a date and time for your video call';
    return <Header title={title_text} subtitle={subtitle_text} />
  }

  render_date_boxes = () => {
    let dates_arr  = this.state.selection_display_dates || [];
    let date_boxes = [];

    let selected_date_obj  = new Date(this.state.selected_date_str);
    let month_str          = DateUtils.getLongMonth(selected_date_obj) + ' ' + DateUtils.getYear(selected_date_obj);

    dates_arr.forEach((date) => {
      let date_number = DateUtils.getDateNumber(date);
      let day_str     = DateUtils.getShortWeekday(date);
      let cleaned_str = this.get_cleaned_time_str(date.toString())
      let is_selected = this.state.selected_date_str === cleaned_str;
      let border_clr  = is_selected ? StyleUtils.get_color() : '#e7e7e7';
      let text_color  = is_selected ? 'white'   : '#1A1D1F';
      let bg_color    = is_selected ? StyleUtils.get_color() : 'white';
      let green_dot   = this.state.available_dots_dict[cleaned_str] === true ? true : false;

      date_boxes.push(
        <div className='flex-container-column universal-button'
             style={{ borderWidth: 1, borderColor: border_clr, borderRadius: 10, backgroundColor: bg_color, padding: 10, alignItems: 'center', width: 70, marginRight: 3, marginLeft: 3 }}
             onClick={ () => {
               this.pull_available_slots_for_date(cleaned_str);
               this.setState({ ...this.state, selected_date_str: cleaned_str });
             }}>
          <div className='flex-container-row' style={{ alignItems: 'center' }}>
            <div style={{ marginBottom: 5, height: 6, width: 6, borderRadius: 3, backgroundColor: green_dot ? '#66e166' : '#e7e7e7' }}></div>
            <Text style={{ marginBottom: 5, color: text_color, fontSize: 14, marginLeft: 5, marginRight: 5 }}>{ day_str.toUpperCase() }</Text>
            <div style={{ width: 6 }} />
          </div>
          <Text style={{ fontSize: 22, fontWeight: 'bold', color: text_color }}>{ date_number }</Text>
        </div>
      )
    });
    return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
      { date_boxes }
    </div>
  }

  render_top_dates_row = () => {

    let dates_arr      = this.state.selection_display_dates || [];
    let today_date_str = new Date().toString();
        today_date_str = this.get_cleaned_time_str(today_date_str);
    let first_date_str = dates_arr && dates_arr[0] ? dates_arr[0] : '';
        first_date_str = this.get_cleaned_time_str(first_date_str.toString());
    let disable_prev   = today_date_str.toString() === first_date_str.toString();
    let disable_next   = this.disable_next_button(first_date_str.toString());
    let month_str      = DateUtils.getLongMonth(today_date_str);
    let year_str       = DateUtils.getYear(today_date_str);

    return <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className='universal-button'
             style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 20, borderWidth: 1, borderColor: disable_prev ? '#f5f5f5' : '' }}
             onClick={ () => {
               if (disable_prev) {
                 return;
               }
               this.navigate_dates(true, false)
             }}>
          <span style={{ fontSize: 15, marginRight: 3, color: disable_prev ? '#e7e7e7' : '#1A1D1F' }} className='fas fa-chevron-left' />
        </div>
        <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#1A1D1F' }}>{ month_str + ' ' + year_str }</Text>
        <div className='universal-button'
             style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 40, height: 40, borderRadius: 20, borderWidth: 1 }}
             onClick={ () => {
               if (disable_next) {
                 return;
               }
               this.navigate_dates(false, true)
             }}>
          <span style={{ fontSize: 15, marginLeft: 3, color: disable_next ? '#e7e7e7' : '#1A1D1F' }} className='fas fa-chevron-right' />
        </div>
      </div>
      { this.render_date_boxes() }
    </div>
  }

  render_slots_list = () => {
    let slots_array = this.state.selected_day_slots || [];

    let slot_rows = slots_array.map((slot, ind) => {
      let slot_date   = new Date(slot.time);
      let start_time  = DateUtils.getTime(slot_date);
      let duration    = slot.duration ? slot.duration.toString() : '15';
      let is_selected = this.state.selected_slot && this.state.selected_slot._id === slot._id ? true : false;
      let display_btn = is_selected;
      let display_confirm = this.state.booked_cliked_id === slot._id;
      let is_loading      = this.state.loading_slot_id  === slot._id;
      let slot_label      = start_time + ' - ' + duration + ' Minutes';
          slot_label      = this.state.is_training ? start_time : slot_label;

      if (slot && (slot.available === false || slot.booked === true)) {
        return null;
      }

      return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FCFCFC', height:48, borderRadius: 12, alignItems: 'center', justifyContent: 'center', marginBottom: 10, borderWidth: 2, borderColor: '#EFEFEF' }}
                  className='universal-button'
                  key={slot._id}
                  onClick={ () => {
                    this.setState({ selected_slot: slot })
                  }}>
        { !is_loading ? <Text style={{ fontWeight: 'bold', color: 16, color: '#1A1D1F', fontSize: 14 }}>{ slot_label }</Text>
                      : <div style={{ height: 24 }}><Spinner /></div> }
      </div>
    })

    let loading  = this.state.loading_date_slots === true ? true : false;
    let no_slots = slot_rows.length === 0 && loading === false ? true : false;

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
      { no_slots ? null : slot_rows }
      { no_slots ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50}}>
                     <span className='fas fa-calendar-week' style={{ fontSize: 40, color: '#DADADA' }} />
                     <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 10, color: '6F767E' }}>No appointments available on this date</Text>
                   </div>
                 : null }
      { loading  ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50}}>
                     <Player autoplay loop src={ require('../animations/pet-hugs.json') } style={{ height: 50, width: 50 }} />
                   </div>
                 : null }
    </div>
  }

  render_selected_slot = () => {
    let selected_slot = this.state.selected_slot;
    let slot_date     = new Date(selected_slot.time);
    let slot_date_str = DateUtils.getLongMonth(slot_date) + ' ' + DateUtils.getDateNumber(slot_date) + ', ' + DateUtils.getYear(slot_date);
    let start_time    = DateUtils.getTime(slot_date);
    let duration      = selected_slot.duration ? selected_slot.duration.toString() : '15';
    let apt_time      = start_time + ' - ' + duration + ' Minutes';
        apt_time      = this.state.is_training ? start_time : apt_time;

    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

      <div style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderColor: '#EFEFEF', borderRadius: 12, padding: 20, paddingTop: 15, paddingBottom: 15, boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.04)' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <span className='fas fa-calendar-alt' style={{ color: StyleUtils.get_color(), fontSize: 20, width: 30 }} />
          <Text style={{ fontSize: 16, color: '#1C2123' }}>{ slot_date_str }</Text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span className='fas fa-clock' style={{ color: StyleUtils.get_color(), fontSize: 20, width: 30 }} />
          <Text style={{ fontSize: 16, color: '#1C2123' }}>{ apt_time }</Text>
        </div>
      </div>

      <div style={{ height: 25 }} />

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', flex: 1, paddingBottom: 20 }}>
        <Error error={this.state.error} style={{ marginBottom: 15 }} />
        <Button title='Book Appointment'
                style={{ width: 330, marginTop: 10, height: 55, alignSelf: 'center', marginBottom: 10 }}
                titleStyle={{ fontSize: 16 }}
                loading={this.state.loading_confirm_appointment}
                onClick={ async () => {
                  this.setState({ loading_confirm_appointment: true, error: '' });

                  let care_consultation      = getItem('care_consultation');
                  let partner                = getItem('partner');
                  let care_consultation_code = care_consultation && care_consultation.code ? care_consultation.code : '';
                  let partner_code           = partner && partner.code ? partner.code : '';

                  let care_consultation_id   = getItem('care_consultation_id');
                  let slot_id                = this.state.selected_slot && this.state.selected_slot._id ? this.state.selected_slot._id : '';
                  let request_data           = { care_consultation_id: care_consultation_id, availability_slot_id: slot_id }
                  let assign_video_res       = await ConsultationController.assignVideoAppointment(request_data);
                  let error_message          = assign_video_res && assign_video_res.error ? assign_video_res.error : '';

                  if (assign_video_res && assign_video_res.success) {
                    this.props.navigate(Routes.APPOINTMENT + '/' + partner_code + '-' + care_consultation_code);
                  }

                  this.setState({ loading_confirm_appointment: false, error: error_message });
                }}/>
        <Button title='Cancel'
                grey={true}
                style={{ marginBottom: 5, height: 50, width: 330 }}
                titleStyle={{ fontSize: 16 }}
                onClick={ () => {
                  if (this.state.loading_confirm_appointment) {
                    return;
                  }

                  this.setState({ selected_slot: null });
                }}/>
      </div>
    </div>
  }

  render_availablility = () => {
    let selected_slot = this.state.selected_slot;

    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      { !selected_slot ? this.render_top_dates_row() : null }
      { !selected_slot ? this.render_slots_list()    : null }
      {  selected_slot ? this.render_selected_slot() : null }
    </div>
  }

  render_already_scheduled = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, paddingRight: 20, paddingLeft: 20 }}>
      <Text style={{ fontWeight: 'bold', fontSize: 22 }}>{ 'Video Appointment Scheduled' }</Text>
      <Text style={{ color: 'grey',  fontSize: 16 }}>{ 'You have a scheduled video appointment' }</Text>
      <Button title='View Appointment Details'
              style={{ height: 55, marginTop: 15, width: 250 }}
              onClick={ () => {
                let care_consultation      = getItem('care_consultation');
                let partner                = getItem('partner');
                let care_consultation_code = care_consultation && care_consultation.code ? care_consultation.code : '';
                let partner_code           = partner && partner.code ? partner.code : '';

                this.props.navigate(Routes.APPOINTMENT + '/' + partner_code + '-' + care_consultation_code);
              }}/>
    </div>
  }

  render_screen_loading = (screen_title) => {
    return <Screen>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        { this.render_top_header() }
        <div style={{ height: 50 }} />
        <Loading type='calendar' title='Retrieving Appointments...' />
      </div>
    </Screen>
  }

  render() {
    let display_phone_input  = this.state.user && !this.state.user.phone_number;
    let display_avialability = this.state.user &&  this.state.user.phone_number;
    let display_scheduled    = this.state.display_scheduled;
        display_avialability = display_scheduled ? false : display_avialability;
        display_phone_input  = display_scheduled ? false : display_phone_input;

    if (this.state.phone_requirement_disabled) {
      display_phone_input  = false;
      display_avialability = true;
    }

    if (this.state.loading_screen) {
      return this.render_screen_loading();
    }

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center', flex: 1 }}>
          { this.render_top_header(display_phone_input) }
          { display_phone_input  ? this.render_phone_input()       : null }
          { display_avialability ? this.render_availablility()     : null }
          { display_scheduled    ? this.render_already_scheduled() : null }
        </div>
      </Screen>
    );
  }

  pull_available_slots_for_date = async (date) => {
    let care_consultation = getItem('care_consultation');
    let practice_id = getItem('practice_id');
    let partner_id  = getItem('partner_id');
    let is_training = care_consultation && care_consultation.is_training === true;
    let avail_slots = [];

    let intial_request_data = {
      practice_id: practice_id,
      partner_id: partner_id,
      date: date,
    }

    let today_date_str = new Date().toString();
        today_date_str = this.get_cleaned_time_str(today_date_str);

    if (intial_request_data.date === today_date_str) {
      intial_request_data.date = (new Date()).toString();
    }

    if (is_training) {
      let train_slots  = await ConsultationController.getTrainingAvailableSlots(intial_request_data);
      avail_slots      = [ ...train_slots ];
    } else {
      let health_slots = await ConsultationController.getAvailableSlots(intial_request_data);
      avail_slots      = [ ...health_slots ];
    }

    this.setState({ selected_day_slots: avail_slots, is_training: is_training });
  }

  navigate_dates = (prev, next) => {
    let existing_display_array = this.state.selection_display_dates || [];
    let first_date             = existing_display_array && existing_display_array[0] ? existing_display_array[0] : new Date().toString();
    let last_date              = existing_display_array && existing_display_array[existing_display_array.length - 1] ? existing_display_array[existing_display_array.length - 1] : new Date().toString();
    let updated_display_array  = [];

    if (prev) {
      let base_date = new Date(first_date);

      let min_date = new Date();
          min_date.setTime(base_date.getTime() - (4 * 24 * 60 * 60 * 1000));

      if (min_date.getDate() < new Date().getDate()) {
        let default_dates_arr = this.get_display_dates_array(new Date().toString());
        this.setState({ ...this.state, selection_display_dates: default_dates_arr })
        return;
      }

      let date_1 = new Date();
          date_1.setTime(base_date.getTime() - (4 * 24 * 60 * 60 * 1000));
      let date_2 = new Date();
          date_2.setTime(base_date.getTime() - (3 * 24 * 60 * 60 * 1000));
      let date_3 = new Date();
          date_3.setTime(base_date.getTime() - (2 * 24 * 60 * 60 * 1000));
      let date_4 = new Date();
          date_4.setTime(base_date.getTime() - (1 * 24 * 60 * 60 * 1000));
      updated_display_array = [date_1, date_2, date_3, date_4];
      this.pull_four_date_slots(date_1, date_2, date_3, date_4);
    }

    if (next) {
      let base_date = new Date(last_date);

      let date_1 = new Date();
          date_1.setTime(base_date.getTime() + (1 * 24 * 60 * 60 * 1000));
      let date_2 = new Date();
          date_2.setTime(base_date.getTime() + (2 * 24 * 60 * 60 * 1000));
      let date_3 = new Date();
          date_3.setTime(base_date.getTime() + (3 * 24 * 60 * 60 * 1000));
      let date_4 = new Date();
          date_4.setTime(base_date.getTime() + (4 * 24 * 60 * 60 * 1000));
      updated_display_array = [date_1, date_2, date_3, date_4];
      this.pull_four_date_slots(date_1, date_2, date_3, date_4);
    }

    if (updated_display_array.length > 0) {
      this.setState({ ...this.state, selection_display_dates: updated_display_array })
    }
  }

  get_cleaned_time_str = (date_str) => {
    let date_obj = new Date(date_str);
    date_obj.setHours(0);
    date_obj.setMinutes(0);
    date_obj.setSeconds(0);
    date_obj.setMilliseconds(0);
    return date_obj.toString();
  }

  disable_next_button = (first_date_str) => {
    let first_date_object = new Date(first_date_str);
    let today_date_object = new Date();
    let disable_button    = false;

    if (first_date_object > today_date_object) {
      disable_button = true;
    }

    return disable_button;
  }

  get_display_dates_array = (date_str) => {
    let selected_date_str = this.state.selected_date_str;

    if (date_str) {
      selected_date_str = date_str;
    }

    let date_1 = new Date(selected_date_str);

    let date_2 = new Date();
        date_2.setTime(date_1.getTime() + (1 * 24 * 60 * 60 * 1000));
    let date_3 = new Date();
        date_3.setTime(date_1.getTime() + (2 * 24 * 60 * 60 * 1000));
    let date_4 = new Date();
        date_4.setTime(date_1.getTime() + (3 * 24 * 60 * 60 * 1000));

    return [date_1, date_2, date_3, date_4];
  }

  pull_four_date_slots = async (date_1, date_2, date_3, date_4) => {
    let care_consultation = getItem('care_consultation');
    let practice_id = getItem('practice_id');
    let partner_id  = getItem('partner_id');
    let is_training = care_consultation && care_consultation.is_training === true;
    let avail_dots  = {};

    let date_1_cleaned = this.get_cleaned_time_str(date_1);
    let date_2_cleaned = this.get_cleaned_time_str(date_2);
    let date_3_cleaned = this.get_cleaned_time_str(date_3);
    let date_4_cleaned = this.get_cleaned_time_str(date_4);

    let date_1_request = { practice_id: practice_id, partner_id: partner_id, date: date_1_cleaned };
    let date_2_request = { practice_id: practice_id, partner_id: partner_id, date: date_2_cleaned };
    let date_3_request = { practice_id: practice_id, partner_id: partner_id, date: date_3_cleaned };
    let date_4_request = { practice_id: practice_id, partner_id: partner_id, date: date_4_cleaned };

    if (is_training) {
      let train_slots_1 = await ConsultationController.getTrainingAvailableSlots(date_1_request);
      let train_slots_2 = await ConsultationController.getTrainingAvailableSlots(date_2_request);
      let train_slots_3 = await ConsultationController.getTrainingAvailableSlots(date_3_request);
      let train_slots_4 = await ConsultationController.getTrainingAvailableSlots(date_4_request);

      avail_dots[date_1_cleaned] = train_slots_1 && train_slots_1.length > 0 ? true : false;
      avail_dots[date_2_cleaned] = train_slots_2 && train_slots_2.length > 0 ? true : false;
      avail_dots[date_3_cleaned] = train_slots_3 && train_slots_3.length > 0 ? true : false;
      avail_dots[date_4_cleaned] = train_slots_4 && train_slots_4.length > 0 ? true : false;

    } else {
      let health_slots_1 = await ConsultationController.getAvailableSlots(date_1_request);
      let health_slots_2 = await ConsultationController.getAvailableSlots(date_2_request);
      let health_slots_3 = await ConsultationController.getAvailableSlots(date_3_request);
      let health_slots_4 = await ConsultationController.getAvailableSlots(date_4_request);

      avail_dots[date_1_cleaned] = health_slots_1 && health_slots_1.length > 0 ? true : false;
      avail_dots[date_2_cleaned] = health_slots_2 && health_slots_2.length > 0 ? true : false;
      avail_dots[date_3_cleaned] = health_slots_3 && health_slots_3.length > 0 ? true : false;
      avail_dots[date_4_cleaned] = health_slots_4 && health_slots_4.length > 0 ? true : false;
    }

    this.setState({ available_dots_dict: avail_dots });
  }

  pull_availabilities_for_dots = () => {
    let today_date_str = new Date().toString();
        today_date_str = this.get_cleaned_time_str(today_date_str);

    let date_1 = new Date(today_date_str);
    let date_2 = new Date();
        date_2.setTime(date_1.getTime() + (1 * 24 * 60 * 60 * 1000));
    let date_3 = new Date();
        date_3.setTime(date_1.getTime() + (2 * 24 * 60 * 60 * 1000));
    let date_4 = new Date();
        date_4.setTime(date_1.getTime() + (3 * 24 * 60 * 60 * 1000));

    this.pull_four_date_slots(date_1, date_2, date_3, date_4);
  }

}
