import React from 'react';
import UtilitiesController from './utilitiesController';
import AuthController      from './authController';

export default class PetController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.message
                }
        }
    };

    static getAllPets = PetController.exceptionWrapper(async () => {
      let response = await UtilitiesController.post('/v5/api/pets/get/pets', {}, true);
      let pets     = [];

      if (response.success) {
        pets = response && response.data && response.data.pets ? response.data.pets : [];
      }

      return pets;
    });

    static getPet = PetController.exceptionWrapper(async (patient_id) => {
      let response = await UtilitiesController.post(`/v5/api/pets/get/pet`, { patient_id: patient_id }, true);
      return response;
    });

    static updatePet = PetController.exceptionWrapper(async (data) => {
      let response = await UtilitiesController.post(`/v5/api/pets/update/pet`, data, true);
      return response;
    });

    static addPet = PetController.exceptionWrapper(async (data) => {
      let response = await UtilitiesController.post(`/v5/api/pets/new/pet`, data, true);
      return response;
    })

}
