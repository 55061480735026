import React from "react";
import { Header } from '../containers';
import { Player }             from '@lottiefiles/react-lottie-player';
import { StringUtils, StyleUtils } from '../utils';
import { getItem, setItem }        from '../Storage';
import { Button, Text, Input, CheckBox, Error, Line } from '../components';
import PetsController from '../controllers/petsController';
import '../css/default.css';

export default class PetSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });
    let pets_array        = await PetsController.getAllPets();

    this.setState({ pets: pets_array });
  }

  componentDidUpdate = () => {

  }

  render_pets_list = () => {
      let partner     = getItem('partner')
      let pets_arr    = this.state.pets || [];
      let pets_rows   = pets_arr.map((pet) => {
        let is_loading = this.state.loading_pet_id === pet._id;
        let is_dog     = pet && pet.type === 'dog';
        let is_cat     = pet && pet.type === 'cat';
        let is_other   = pet && !is_dog && !is_cat;
        let is_selected= this.state.selected_pet_id === pet._id;
        return <div className='universal-button'
                    key={pet._id}
                    style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderColor: '#e7e7e7', borderStyle: 'solid', padding: 15, borderRadius: 12, marginBottom: 5 }}
                    onClick={ async () => {
                      if (this.state.loading_pet_id) {
                        return;
                      }
                      let features_key = pet.name.toLowerCase() + '_' + pet.type.toLowerCase();
                      this.setState({
                        selected_pet_id: is_selected ? '' : pet._id,
                        selected_pet_features_key: is_selected ? '' : features_key
                      });
                    }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 40, height: 40, marginRight: 15, overflow: 'hidden' }}>
                { is_dog   ? <Player autoplay loop src={ require('../animations/dog-walking.json') }      style={{ height: '60px', width: '60px', marginTop: -10, marginLeft: -10  }} /> : null }
                { is_cat   ? <Player autoplay loop src={ require('../animations/cat-tail-wag.json') }     style={{ height: '90px', width: '90px', marginTop: -30, marginLeft: -30  }} /> : null }
                { is_other ? <Player autoplay loop src={ require('../animations/cow-eating-grass.json') } style={{ height: '70px', width: '70px', marginTop: -8, marginBottom: -5 }} />  : null }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: 12, fontWeight: 'bold', color: '#6F767E' }}>{ StringUtils.displayGender(pet.gender) }</Text>
                <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#1A1D1F' }}>{ StringUtils.displayName(pet) }</Text>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              { is_selected ? <span className='fas fa-check-circle' style={{ fontSize: 20, color: '#75D392' }} /> : null }
            </div>
          </div>
        </div>
      }).filter((row) => { return row });

      let no_pets  = pets_rows.length === 0;

      return <div style={{ display: 'flex', flexDirection: 'column' }}>

        <Error error={this.state.updated_error} style={{ marginBottom: 20, marginTop: 10, alignSelf: 'center' }} />

        { pets_rows }

        { no_pets ? <div style={{ height: 80, width: 120, overflow: 'hidden', alignSelf: 'center' }}>
                      <Player autoplay loop src={ require('../animations/cat-tail-wag.json') } style={{ height: '150px', width: '150px', marginTop: -35, marginLeft: -14 }} />
                    </div>
                  : <div style={{ height: 5 }} /> }

        <Line hide={true} style={{ marginTop: 10, marginBottom: 15 }}/>
      </div>
  }

  render_continue_button = () => {
    if (!this.state.selected_pet_id) {
      return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button title='Continue'
              style={{ width: 300, marginTop: 5, height: 55, alignSelf: 'center' }}
              titleStyle={{ fontSize: 16 }}
              loading={this.state.loading_continue}
              onClick={ async () => {
                this.setState({ loading_continue: true, updated_error: '' });

                let pet_id        = this.state.selected_pet_id;
                let patient_id    = pet_id;
                let features_key  = this.state.selected_pet_features_key;

                if (this.props.pet_selection_action) {
                  this.props.pet_selection_action({ patient_id: pet_id, pet_features_key: features_key });
                }

                this.setState({ loading_continue: false });
              }}/>
    </div>
  }

  render() {
    return <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
      <Header title={ 'Welcome ' } subtitle='Select a pet to get started' />
      { this.render_pets_list()       }
      { this.render_continue_button() }
    </div>
  }

}
