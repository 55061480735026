import React from "react";
import '../css/default.css';
import { Button, Text, Input, Screen, Error, Loading } from '../components';
import { Header, IntakeQuestion }     from '../containers';
import { NavigationUtils, DateUtils } from '../utils';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import AuthController         from '../controllers/authController';
import ActionController       from '../controllers/actionController';
import UtilitiesController    from '../controllers/utilitiesController';
import ConsultationController from '../controllers/consultationController';

export default class IntakeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intake_questions: [],
      intake_responses: {},
      loading_save_responses: false,
      loading_screen: false
    };
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });

    let practice_id = getItem('practice_id');
    let partner_id  = getItem('partner_id');
    let care_consultation_id  = getItem('care_consultation_id');
    let existing_responses_id = getItem('intake_responses_id');
    let intake_questions      = await ConsultationController.getIntakeQuestions({ practice_id, partner_id });

    if (care_consultation_id) {
      let intake_responses_res  = await ConsultationController.getIntakeResponse(care_consultation_id);
      let responses_array       = intake_responses_res && intake_responses_res.data && intake_responses_res.data.intake_response && intake_responses_res.data.intake_response.responses ? intake_responses_res.data.intake_response.responses : [];
      let existing_response_id  = intake_responses_res && intake_responses_res.data && intake_responses_res.data.intake_response && intake_responses_res && intake_responses_res.data && intake_responses_res.data.intake_response._id ? intake_responses_res.data.intake_response._id : '';
      setItem('intake_responses_id', existing_response_id);
    }

    await DateUtils.delay(1200)

    this.setState({ intake_questions: intake_questions, loading_screen: false });
  }

  render_intake_questions = () => {
    let questions_arr     = this.state.intake_questions || [];
    let care_consultation = getItem('care_consultation');
    let category          = care_consultation && care_consultation.category    ? care_consultation.category    : '';
    let is_training       = care_consultation && care_consultation.is_training ? care_consultation.is_training : '';

    let question_rows = questions_arr.map((question, idx) => {
      let is_core_question = question.category === 'core_questions';
      let category_matched = question.category === category;
      let display_question = (!is_training && is_core_question) || (is_training && category_matched) || (!is_training && category_matched);
      if (display_question) {
        let question_index = question.index;
        return <IntakeQuestion key={idx}
                               question={question}
                               on_change={ (intake_response) => {
                                 let updated_responses = Object.assign({}, this.state.intake_responses);
                                 updated_responses[question_index] = intake_response;
                                 this.setState({ intake_responses: updated_responses });
                               }}/>
      }
    }).filter((row) => { return row });

    return <div className='flex-container-column' style={{  }}>
      { question_rows }
    </div>
  }

  render_continue_button = () => {
    let intake_responses = this.state.intake_responses ? Object.values(this.state.intake_responses) : [];

    return <div className='flex-container-column' style={{ marginTop: 15, alignItems: 'center' }}>
      <Error error={this.state.error} style={{ marginBottom: 15 }}/>
      <Button title='Continue'
              style={{ width: 300, marginTop: 10, height: 55, alignSelf: 'center' }}
              titleStyle={{ fontSize: 16 }}
              loading={this.state.loading_save_responses}
              onClick={ async () => {
                this.setState({ loading_save_responses: true, error: '' });

                let existing_responses_id = getItem('intake_responses_id');
                let care_consultation_id  = getItem('care_consultation_id');
                let is_existing           = existing_responses_id ? true : false;

                if (!is_existing) {
                  let response_data    = { care_consultation_id: care_consultation_id, responses: intake_responses }
                  let responses_res    = await ConsultationController.createIntakeResponse(response_data);
                  let new_responses_id = responses_res && responses_res.success && responses_res.data && responses_res.data.intake_response && responses_res.data.intake_response._id ? responses_res.data.intake_response._id : '';
                  let is_success       = responses_res && responses_res.success ? true : false;
                  let error_msg        = responses_res && responses_res.error   ? responses_res.error : '';

                  setItem('intake_responses_id', new_responses_id);
                  if (is_success) {
                    await NavigationUtils.navigate_to_consultation(this.props.navigate, true);
                  }
                  this.setState({ loading_save_responses: false, error: error_msg });
                } else {
                  let response_data    = { care_consultation_id: care_consultation_id, responses: intake_responses }
                  let responses_res    = await ConsultationController.editIntakeResponse(response_data);
                  let is_success       = responses_res && responses_res.success ? true : false;
                  let error_msg        = responses_res && responses_res.error   ? responses_res.error : '';

                  if (is_success) {
                    await NavigationUtils.navigate_to_consultation(this.props.navigate, true);
                  }
                  this.setState({ loading_save_responses: false, error: error_msg });
                }

              }}/>
    </div>
  }

  render_screen_loading = (screen_title) => {
    return <Screen shadow={false}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        <Header title={ screen_title } />
        <div style={{ height: 50 }} />
        <Loading type='posts' title='Loading Questions...' />
      </div>
    </Screen>
  }

  render() {

    if (this.state.loading_screen) {
      return this.render_screen_loading('Intake Questions');
    }

    return (
      <Screen shadow={false}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Intake Questions' />
          { this.render_intake_questions() }
          { this.render_continue_button()  }
          <div style={{ height: 50 }}></div>
        </div>
      </Screen>
    );
  }

}
