import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomeScreen, IntakeScreen, ChatScreen, PetsScreen, VideoAppointmentScreen, VideoAvailabilityScreen, VideoLiveScreen } from './screens';
import './App.css';
import { AnimatePresence  } from "framer-motion";
import { useNavigate      } from 'react-router-dom';
import { Routes as routes } from './navigation';

let App = () => {
  let navigate = useNavigate();
  return (
    <Routes>
      <Route exact path={routes.HOME}   element={<HomeScreen   navigate={navigate} />} />
      <Route exact path={routes.INTAKE} element={<IntakeScreen navigate={navigate} />} />
      <Route exact path={routes.PETS}   element={<PetsScreen   navigate={navigate} />} />
      <Route exact path={routes.HOME  + '/:code'}       element={<HomeScreen              navigate={navigate} />} />
      <Route exact path={routes.HOME  + '/connected/:session_code'} element={<HomeScreen  navigate={navigate} />} />
      <Route exact path={routes.CHAT  + '/:code'}       element={<ChatScreen              navigate={navigate} />} />
      <Route exact path={routes.APPOINTMENT + '/:code'} element={<VideoAppointmentScreen  navigate={navigate} />} />
      <Route exact path={routes.AVAILABILITY}           element={<VideoAvailabilityScreen navigate={navigate} />} />
      <Route exact path={routes.LIVE_VIDEO + '/:code' } element={<VideoLiveScreen         navigate={navigate} />} />
    </Routes>
  );
}

export default App;
