import React from "react";
import { StyleUtils }   from '../utils';
import { Button, Text } from '../components';
import '../css/default.css';

export default class IntroSlides extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active_slide_index: 0,
    };
  }

  componentDidMount = () => {

  }

  render_dots = () => {
    let dot_components = [];
    let slides         = this.get_slides();
    let get_dot_color  = StyleUtils.get_color();
    let dots = slides.forEach((slide, i) => {
      let is_active = i === this.state.active_slide_index;
      let bg_color  = is_active ? get_dot_color : '#e7e7e7';
      dot_components.push(<div style={{ height: 7, width: 25, backgroundColor: bg_color, borderRadius: 30, marginRight: 3, marginLeft: 3 }}
                               className='universal-button'
                               onClick={ () => {
                                 this.setState({ active_slide_index: i })
                               }} />);
    });

    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      { dot_components }
    </div>
  }

  render_slides = () => {
    let slides            = this.get_slides();
    let current_slide     = slides && slides[this.state.active_slide_index] ? slides[this.state.active_slide_index] : {};
    let current_image_url = current_slide.image_url;
    let current_slide_msg = current_slide.message;
    let max_width         = 480;
    let window_width      = window.innerWidth;
    let screen_width      = window_width > max_width ? max_width : window_width;
    let button_title      = slides && slides.length - 1 === this.state.active_slide_index ? 'Continue' : 'Next';

    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
      <img style={{ height: '65%', width: '100%', objectFit: 'cover', borderRadius: 5 }} src={ current_image_url } />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '66%', width: screen_width, position: 'absolute' }}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', backgroundColor: 'white', height: 40, borderTopRightRadius: 20, borderTopLeftRadius: 20, borderColor: 'white', borderStyle: 'solid', borderWidth: 0 }}>
          <div className='universal-button' style={{ display: 'flex', flexDirection: 'column', width: 80, alignItems: 'center', justifyContent: 'flex-end', padding: 5, backgroundColor: '#f5f5f5', borderRadius: 30, marginTop: 10, marginRight: 10 }}
               onClick={ () => {
                 if (this.props.button_action) {
                   this.props.button_action();
                 }
               }}>
            <Text style={{ color: 'black', fontWeight: 'bold', fontSize: 14 }}>Skip</Text>
          </div>
        </div>

      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', padding: 20, paddingTop: 0, backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: 110, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ textAlign: 'center', fontSize: 22, fontWeight: 'bold' }}>{ current_slide_msg }</Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 50 }}>
          { this.render_dots() }
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Button title={ button_title }
                  style={{ width: 200 }}
                  onClick={ () => {
                    let last_index = slides.length - 1;
                    let curr_index = this.state.active_slide_index;
                    let new_index  = curr_index + 1 > last_index ? curr_index : curr_index + 1;

                    if (curr_index === last_index && this.props.button_action) {
                      this.props.button_action();
                    }
                    this.setState({ active_slide_index: new_index })
                  }}/>
        </div>
      </div>
    </div>
  }

  render() {
    return this.render_slides();
  }

  get_slides = () => {
    let slides  = [];
    let partner = this.props.partner_name;

    switch (partner) {
      case 'teletails':
        let teletails_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',     message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...teletails_slides ]
        break;
      case 'hills':
        let hills_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Hills3.jpg',     message: "24/7 free online nutritional care, brought to you by Hill’s Pet Nutrition." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/HillsLogo2-1.png', message: 'Convenient, trusted, on-demand vet pro visits available 24/7 from home.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/HillsLogo3-1.png', message: 'Care coordinated with your local clinic, but accessible from home.' },
        ];
        slides = [ ...hills_slides ]
        break;
      case 'drmarty':
        let drmarty_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/DrM2.png',  message: "Text: 24/7 online pet care, brought to you by Dr. Marty Pets." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Our pets are family. With all the ways our dogs and cats love us, it’s natural we provide them with the best food & care.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'We have you covered with convenient, trusted, on-demand vet pro visits available from home.' },
        ];
        slides = [ ...drmarty_slides ]
        break;
      case 'snugglepuppy':
        let snugglepuppy_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising a puppy is hard! We’re here to help, 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...snugglepuppy_slides ]
        break;
      case 'darwins':
        let darwins_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/1%20(5).png', message: "We're here 24/7 to support your pet's health journey." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/2%20(5).png', message: 'Live chats and 1x1 video sessions with caring veterinary professionals.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3%20(3).png', message: 'Nutrition, health, behavior, and more. Talk to an expert immediately.' },
        ];
        slides = [ ...darwins_slides ]
        break;
      case 'thedogstop':
        let thedogstop_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...thedogstop_slides ]
        break;
      case 'zestypaws':
        let zestypaws_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...zestypaws_slides ]
        break;
      case 'wag':
        let wag_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...wag_slides ]
        break;
      case 'fullmoon':
        let fullmoon_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/1.png', message: "Members access 24/7 online pet care, brought to you by Full Moon." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/2.png', message: 'Video and chat sessions with trusted vet pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3.png', message: 'Become a Full Moon member today!' },
        ];
        slides = [ ...fullmoon_slides ]
        break;
      case 'fable':
        let fable_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(21).png',     message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...fable_slides ]
        break;
      case 'getjoy':
        let getjoy_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...getjoy_slides ]
        break;
      case 'wagmo':
        let wagmo_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/wagmo-slider-1.png', message: "Convenient, Trusted, & On-Demand. Virtual Pet Care." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/wagmo-slider-2.png', message: 'Real professionals...not that random person from the dog park.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/wagmo-slider-3.png', message: 'Quit using “Dr. Google”. Connect with someone you can trust.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/wagmo-slider-4.png', message: 'Sessions are available through chat or video - whatever works best  for you!' },
        ];
        slides = [ ...wagmo_slides ]
        break;
      case 'teletailsprod':
        let teletailsprod_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/1-1.png', message: "Does your dog jump, bark, or pull? We can help." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/2-1.png', message: 'Private online training with licensed training pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3-1.png', message: 'Fully customized sessions are 100% focused on your needs.' },
        ];
        slides = [ ...teletailsprod_slides ]
        break;
      case 'westernshore':
        let westernshore_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/TeleTails%20Logos.png', message: "Does your dog jump, bark, or pull? We can help." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/2-1.png', message: 'Private online training with licensed training pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3-1.png', message: 'Fully customized sessions are 100% focused on your needs.' },
        ];
        slides = [ ...westernshore_slides ]
        break;
      case 'dodovet':
        let dodovet_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/TeleTails%20Logos%20(1).png', message: "Does your dog jump, bark, or pull? We can help." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/2-1.png', message: 'Private online training with licensed training pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3-1.png', message: 'Fully customized sessions are 100% focused on your needs.' },
        ];
        slides = [ ...dodovet_slides ]
        break;
      case 'petstable':
        let petstable_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/PT1.png', message: "Raising our fur-fam is hard! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/3-2.png', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/PT3.png', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...petstable_slides ]
        break;
      default:
        let default_slides = [
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/Untitled%20design%20(5).png',  message: "Raising our fur-fam is hard!! We’re here 24/7." },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-1.jpg', message: 'Live chats and 1x1 video sessions with caring veterinary pros.' },
          { image_url: 'https://4728109.fs1.hubspotusercontent-na1.net/hubfs/4728109/New-2.jpg', message: 'Nutrition, health, behavior, and more! Talk to a pro immediately.' },
        ];
        slides = [ ...default_slides ]
    }

    return slides;
  }
}
