import React from "react";
import axios from 'axios';
import { Player }      from '@lottiefiles/react-lottie-player';
import { config }      from '../config/index';
import { StringUtils, StyleUtils } from '../utils';
import { getItem, setItem } from '../Storage';
import { Button, Text, Input, Line, Spinner } from '../components';
import { ChatMessage } from '../containers';
import '../css/default.css';
import ConsultationController from '../controllers/consultationController';
import UtilitiesController    from '../controllers/utilitiesController';

export default class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pulled_messages: false,
      text: '',
      messages: [],
      loading_attachment_button: false,
      socket_bubble_on: false,
      bubble_seconds: 0,
      time_elapsed: 0,
      display_type_bubble: false
    };

    this.socket = null;
    this.consultation_socket = null;
  }

  socket_connect(care_consultation_id) {
    return UtilitiesController.getSocket({ name: `consultation_${care_consultation_id}`, query: 'consultation_id=' + care_consultation_id })
  }

  consultation_socket_connect(care_consultation_id) {
    return UtilitiesController.getSocket({ name: `consultation_${care_consultation_id}`, query: 'notification=' + care_consultation_id, notification: true })
  }

  connect_sockets = (care_consultation_id) => {

    if (!this.consultation_socket) {
      this.consultation_socket = this.consultation_socket_connect(care_consultation_id);
    }

    if (!this.socket) {
      this.socket = this.socket_connect(care_consultation_id);
    }

    this.consultation_socket.on('care_consultation_updated', async () => {
      if (this.props.care_consultation_update_action) {
        this.props.care_consultation_update_action();
      }
    });

    this.socket.on('care_chat_message', async (msg) => {
      this.get_care_consultation_messages(this.state.care_consultation_id);
    });

    this.socket.on('typing_bubble', (bubble_data) => {
      this.toggle_chat_bubble(bubble_data);
    });

  }

  componentDidMount = () => {
    let second_timer         = setInterval(this._onEverySecond, 1000);
    let care_consultation_id = getItem('care_consultation_id');
    this.get_care_consultation_messages(care_consultation_id);
    this.connect_sockets(care_consultation_id);
    this.setState({ care_consultation_id: care_consultation_id, second_timer: second_timer });
  }

  componentDidUpdate = () => {
    let care_consultation_id = getItem('care_consultation_id');
    if (care_consultation_id && !this.state.pulled_messages) {
      this.get_care_consultation_messages(care_consultation_id);
      this.setState({ pulled_messages: true });
    }
  }

  componentWillUnmout = () => {
    clearInterval(this.state.second_timer);
  }

  render_top_section = () => {
    let provider_name = this.props.care_consultation && this.props.care_consultation.provider ? StringUtils.displayName(this.props.care_consultation.provider) : 'Provider';
    let image_url     = this.props.care_consultation && this.props.care_consultation.provider && this.props.care_consultation.provider.photo_url ? this.props.care_consultation.provider.photo_url : '';
    return <div style={{ display: 'flex', flexDirection: 'column', height: 60, backgroundColor: 'white', borderTopRightRadius: 20, borderTopLeftRadius: 20 }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 15 }}>
          { image_url ? <img style={{ height: 40, width: 40, objectFit: 'cover', borderRadius: 20 }} src={image_url} />
                      : <div style={{ backgroundColor: '#e7e7e7', height: 40, width: 40, borderRadius: 40 }}>
                          <span className='fas fa-user-alt' style={{ color: 'white', marginTop: 10, marginLeft: 12 }} />
                        </div> }
          <Text style={{ marginLeft: 10, fontWeight: 'bold', color: '#141415', fontSize: 15 }}>{ provider_name }</Text>
        </div>
      </div>
    </div>
  }

  render_chat_section = () => {
    let chat_section_height = this.props.screen_height - 95 - 20 - 60 - 20;
    let messages     = this.get_appended_messages();

    if (this.state.display_type_bubble) {
      messages = [ { type: 'BUBBLE' }, ...messages ];
    }

    let message_rows = messages.map((message, i) => {
      return <ChatMessage message={ message } key={i} />
    }).filter((row) => { return row });

    return <div style={{ display: 'flex', backgroundColor: 'white', overflow: 'auto', flexDirection: 'column-reverse', height: chat_section_height }}>
      <div style={{ height: 10 }} />
      { message_rows }
      <div style={{ flex: '0 0 auto', height: 100, width: 250, height: 10 }} />
    </div>
  }

  render_attachment_preview = () => {
    if (!this.state.file) {
      return null;
    }

    let not_supported   = <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ fontSize: 13, color: 'grey', textAlign: 'center' }}>The format for this file is not supported.</span>
                          </div>
    let image_component = <div style={{ display: 'flex', flexDirection: 'column', width: 260, alignItems: 'center' }}>
                            <img style={{ height: 250, width: 'auto', maxWidth: 260, objectFit: 'contain', borderRadius: 5 }} alt='Message Attachment' src={this.state.file} />
                          </div>
    let video_component = <div style={{ display: 'flex', flexDirection: 'column', width: 260, alignItems: 'center' }}>
                            <video style={{ width: 250, height: 'auto', maxHeight: 260, backgroundColor: 'black', borderRadius: 10 }} controls>
                              <source src={this.state.file} type="video/mp4" />
                              Sorry, your browser doesn't support this video format.
                            </video>
                          </div>
    let pdf_component   = <div style={{ display: 'flex', flexDirection: 'column', width: 260, alignItems: 'center' }}>
                            <div className='flex-container-row' style={{ alignItems: 'center' }}>
                              <span className='fas fa-file-pdf' style={{ color: '#ff6262', fontSize: 30, marginRight: 10 }}></span>
                              <div className='flex-container-column' style={{ marginRight: 5}}>
                                <span style={{ fontSize: 13, color: '#474747' }}>{ this.state.pdf_name }</span>
                              </div>
                            </div>
                          </div>

    return <div className='shadow-[0_10px_60px_1px_rgba(0,0,0,0.5)]' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white', alignSelf: 'center', width: 300, height: 400, marginTop: 50, position: 'absolute', borderRadius: 20, padding: 20 }}>
      <div />

      { !this.state.attachment_type            ? not_supported   : null }
      { this.state.attachment_type === 'IMAGE' ? image_component : null }
      { this.state.attachment_type === 'VIDEO' ? video_component : null }
      { this.state.attachment_type === 'PDF'   ? pdf_component   : null }

      <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button title='Cancel'
                  grey={true}
                  style={{ height: 55, marginRight: 2, flex: 1 }}
                  titleStyle={{ fontSize: 16 }}
                  onClick={ () => {
                    if (this.state.loading_attachment_button) {
                      return;
                    }
                    this.setState({ file: null, to_upload: null, attachment_type: '', pdf_name: '' });
                  }}/>
          <Button title='Send'
                  style={{ height: 55, marginLeft: 2, flex: 1 }}
                  titleStyle={{ fontSize: 16 }}
                  loading={this.state.loading_attachment_button}
                  onClick={ () => {
                    this.upload_attachment();
                    this.setState({ loading_attachment_button: true });
                  }}/>
      </div>

    </div>
  }

  render_input_section = () => {
    let is_resolved = this.props.is_resolved === true ? true : false;

    if (is_resolved) {
      return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', padding: 10, paddingTop: 8 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 95, width: '100%' }}>
          <span className='fas fa-check-circle' style={{ color: '#27AE60', fontSize: 18 }} />
          <Text style={{ fontSize: 14, fontWeight: 'bold', color: '#4c4c4c', marginLeft: 5 }}>Consultation Completed</Text>
        </div>
      </div>
    }

    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', padding: 10, paddingTop: 8 }}>

      <textarea className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"
                value={this.state.text}
                style={{ flex: 1, height: 100 }}
                placeholder='Write a message'
                onChange={ (event) => {
                  let user_id              = getItem('user_id');
                  let care_consultation_id = getItem('care_consultation_id');

                  if (user_id && care_consultation_id) {
                    this.socket.emit('typing_bubble', { typer_id: user_id, consultation_id: care_consultation_id });
                  }
                  if (event && event.target && event.target.value) {
                    this.setState({ text: event.target.value });
                  }
                  if (event && event.target && event.target.value === '') {
                    this.setState({ text: '' });
                  }
                }}/>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingLeft: 10, width: 50, height: '100%' }}>
        <input className="file-upload-input"
                 type='file'
                 id="file-upload"
                 style={{ display: 'flex', flexDirection: 'column', height: 40, width: 40, backgroundColor: '#e7e7e7', alignItems: 'center', justifyContent: 'center', borderRadius: 20 }}
                 onChange={ (event) => {
                   this.setState({ loading_attachment_button: true });

                   this.handle_file_upload(event);

                   this.setState({ loading_attachment_button: false });
                 }}
                />
        <label className="flex-container-column universal-button" htmlFor="file-upload">
          <div className='universal-button' style={{ display: 'flex', flexDirection: 'column', height: 40, width: 40,  backgroundColor: '#e7e7e7', alignItems: 'center', justifyContent: 'center', borderRadius: 20 }}>
            <span className='fas fa-paperclip' />
          </div>
        </label>
        <Line style={{ marginBottom: 10, marginTop: 10 }} />
        <div className={ 'universal-button flex-container-column ' + StyleUtils.get_class('gradient-background') }
             style={{ height: 40, width: 40, borderRadius: 10, alignItems: 'center', justifyContent: 'center', paddingRight: 4 }}
             onClick={ async () => {
               this.setState({ loading_send_button: true });

               if (!this.state.text) {
                 this.setState({ loading_send_button: false });
                 return;
               }

               let message_data = {
                 content: { text: this.state.text },
                 consultation_id: this.state.care_consultation_id,
                 type: 'TEXT'
               }

               let send_msg_res = await ConsultationController.sendCareConsultationMessage(message_data);
               let is_success   = send_msg_res && send_msg_res.success ? true : false;

               if (is_success) {
                 this.get_care_consultation_messages(this.state.care_consultation_id);
               }

               this.setState({ loading_send_button: false, text: is_success ? '' : this.state.text });
             }}>
          { this.state.loading_send_button ? <Player autoplay loop src={ require('../animations/white-spinner.json') } style={{ height: '20px', width: '20px', marginLeft: 4 }} />
                                           : <span className='fas fa-paper-plane' style={{ color: 'white', fontSize: 18 }} /> }
        </div>

      </div>
    </div>
  }

  render() {
    return (
      <div className='shadow-[0_10px_60px_1px_rgba(0,0,0,0.04)]' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        { this.render_top_section()   }
        { this.render_chat_section()  }
        <Line />
        { this.render_input_section() }
        { this.render_attachment_preview() }
      </div>
    );
  }

  handle_file_upload = (event) => {
    let file  = event.target.files[0];
    let files = event.target.files;

    let media_type = '';
    let pdf_name   = '';

    if (file && file.type === 'image/jpeg' || file.type === 'image/png') {
      media_type = 'IMAGE'
    }

    if (file && file.type === 'video/mp4' || file.type === 'video/3gpp' || file.type === 'video/quicktime' || file.type === 'video/3gpp') {
      media_type = 'VIDEO'
    }

    if (file && file.type === 'application/pdf') {
      media_type = 'PDF'
      pdf_name   = file.name || '';
    }

    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ file: reader.result, to_upload: files, attachment_type: media_type, pdf_name: pdf_name });
    }

    reader.readAsDataURL(file);
  }

  upload_attachment = () => {
    let token         = getItem('token');
    let random_id     = getItem('user_id');

    let body = new FormData();
        body.append("file", this.state.to_upload[0])

    let request_url    = `${config.apiURL}/v3/api/file/chat`;
    let request_config = { headers: { 'authorization': token, 'content-type': 'multipart/form-data' } };

    axios.post(request_url, body, request_config).then((response) => {
      let response_data = response.data;
      let url           = response_data && response_data.message && response_data.message.Location ? response_data.message.Location : '';

      if (url) {
        this.send_media_message(url);
      } else {
        this.setState({ ...this.state, loading_attachment_button: false })
      }
    }).catch((error) => {
      console.log(error)
      this.setState({ ...this.state, loading_attachment_button: false });
    });
  }

  send_media_message = async (url) => {
    let consultation_id = getItem('care_consultation_id');
    let selected_media  = this.state.attachment_type;
    let media_type      = selected_media === 'VIDEO' ? 'VIDEO' : '';
        media_type      = selected_media === 'IMAGE' ? 'IMAGE' : media_type;
        media_type      = selected_media === 'PDF'   ? 'PDF'   : media_type;

    let message_data    = { content: { url: url, }, consultation_id: consultation_id, type: media_type }

    let send_msg_res    = await ConsultationController.sendCareConsultationMessage(message_data);
    let is_success      = send_msg_res && send_msg_res.success ? true : false;

    if (is_success) {
      this.get_care_consultation_messages(this.state.care_consultation_id);
    }

    this.setState({ loading_attachment_button: false, file: null, to_upload: null, attachment_type: '', pdf_name: '' });
  }

  get_care_consultation_messages = async (care_consultation_id) => {
    let messages_res  = await ConsultationController.getConsultationChatMessages(care_consultation_id);
    let messages      = messages_res && messages_res.success && messages_res.data && messages_res.data.messages ? messages_res.data.messages : [];
        messages.reverse();
    this.setState({ messages: messages });
  }

  get_appended_messages = () => {
    let date_today = new Date();
    let date_str   = date_today.toString();

    let messages          = this.state.messages || [];
    let care_consultation = this.props.care_consultation;
    let profile_pic       = care_consultation && care_consultation.provider && care_consultation.provider.photo_url ? care_consultation.provider.photo_url : '';
    let provider_bio      = care_consultation && care_consultation.provider && care_consultation.provider.bio       ? care_consultation.provider.bio : '';
    let is_async          = care_consultation && care_consultation.is_async && care_consultation.is_async === true  ? true : false;
    let partner           = care_consultation && care_consultation.partner  ?  care_consultation.partner : {};
    let welcome_messages  = partner && partner.welcome_messages ? partner.welcome_messages : [];
    let full_messages     = [];

    if (care_consultation && care_consultation._id && messages && messages.length < 3) {
      let async_welcome_1 = 'Thanks for reaching out! We are busy helping other pet parents at the moment. Please keep this window open and we’ll be back in a few moments to help!'
      let async_welcome_2 = 'If you’re receiving this during business hours, our representatives are busy helping other clients but will be with you shortly.'
      let online_welcome  = "Welcome! We’re looking forward to chatting with you. We will be with you momentarily. In the meantime, feel free to add a brief summary of your questions/concerns today or any additional information, photos or videos you feel is important.";

      let first_message = {
        consultation_id: care_consultation._id,
        content: {text: is_async ? async_welcome_1 : online_welcome },
        from: 'system',
        type: "TEXT",
        created_at: care_consultation.created_at,
        updated_at: care_consultation.created_at,
      }

      let second_message = {
        consultation_id: care_consultation._id,
        content: {text: async_welcome_2 },
        from: 'system',
        type: "TEXT",
        created_at: care_consultation.created_at,
        updated_at: care_consultation.created_at,
      }

      let first_message_arr = is_async ? [first_message] : [first_message];

      if (welcome_messages && welcome_messages.length > 0 && !is_async) {
        let custom_welcome_messages = welcome_messages.map((message_text) => {
          return {
            consultation_id: care_consultation._id,
            content: {text: message_text },
            from: 'system',
            type: "TEXT",
            created_at: care_consultation.created_at,
            updated_at: care_consultation.created_at,
          }
        })
        first_message_arr = [ ...custom_welcome_messages ]
      }

      if (provider_bio) {
        let pic_message_arr = [];

        if (profile_pic) {
          let pic_message = {
            consultation_id: care_consultation._id,
            content: {url: profile_pic },
            from: 'system',
            type: "IMAGE",
            created_at: care_consultation.created_at,
            updated_at: care_consultation.created_at,
          }

          pic_message_arr = [ pic_message ];
        }

        let bio_message = {
          consultation_id: care_consultation._id,
          content: {text: provider_bio },
          from: 'system',
          type: "TEXT",
          created_at: care_consultation.created_at,
          updated_at: care_consultation.created_at,
        }

        let bio_message_arr = [ bio_message ];

        if (is_async) {
          first_message_arr = [ ...first_message_arr, ...bio_message_arr, ...pic_message_arr ];
        } else {
          first_message_arr = [ ...first_message_arr, ...bio_message_arr, ...pic_message_arr ];
        }
      }

      full_messages = [ ...messages, ...first_message_arr ];
    } else {
      full_messages = [ ...messages ];
    }

    return full_messages;
  }

  toggle_chat_bubble = (bubble_data) => {
    let typer_id        = bubble_data.typer_id;
    let consultation_id = bubble_data.consultation_id;
    let current_user_id = getItem('user_id');
    let bubble_seconds  = this.state.bubble_seconds;
    let display_bubble  = this.state.display_type_bubble;

    if (typer_id && current_user_id !== typer_id) {
      bubble_seconds  = 4;
      display_bubble = true;
    }

    this.setState({ ...this.state, display_type_bubble: display_bubble, bubble_seconds: bubble_seconds })
  }

  _onEverySecond = () => {
    let time_elapsed   = this.state.time_elapsed;
        time_elapsed   = time_elapsed + 1;

    let bubble_seconds = this.state.bubble_seconds;
        bubble_seconds = bubble_seconds > 0 ? bubble_seconds - 1 : bubble_seconds;

    let display_bubble = false;
    let every_fifteen  = time_elapsed % 15 === 0;

    if (bubble_seconds > 0) {
      display_bubble = true;
    }

    if (every_fifteen) {
      this.force_pull_messages();
    }

    this.setState({ ...this.state, time_elapsed: time_elapsed, bubble_seconds: bubble_seconds, display_type_bubble: display_bubble });
  }

  force_pull_messages = () => {
    let current_messages = this.state.messages;
    let last_message     = current_messages && current_messages.length > 0 && current_messages[0] ? current_messages[0] : {};
    let sender_id        = last_message && last_message.from ? last_message.from : '';
    let user_id          = getItem('user_id');

    if (sender_id === user_id) {
      let care_consultation_id = getItem('care_consultation_id');
      this.get_care_consultation_messages(care_consultation_id);
    }
  }
}
