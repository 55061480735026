import React    from "react";
import { Text } from '../components';
import '../css/default.css';

export default class Input extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render_label = (label_text) => {
    return <div style={{ paddingLeft: 0, marginBottom: 8 }}>
      <Text style={{ fontSize: 14, paddingLeft: 4, fontWeight: 'bold', color: '#6F767E' }}>{ label_text }</Text>
    </div>
  }

  render_input_component = () => {
    let input_type      = this.props.type || 'text';
    let input_label     = this.props.label;
    let passed_style    = this.props.style || {};
    let container_style = this.props.container_style || {};
    let placeholder     = this.props.placeholder || '';
    let value           = this.props.value || '';

    return <div style={{ ...container_style }}>
      { input_label ? this.render_label(input_label) : null }
      <input type={input_type}
             value={value}
             disabled={this.props.disabled === true}
             style={{ fontFamily: 'Manrope', fontSize: 16, borderWidth: 2, ...passed_style }}
             placeholder={ placeholder }
             className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-sky-300 focus:bg-white focus:ring-0"
             onChange={ (event) => {
               if (this.props.onChange && event && event.target && event.target.value) {
                 this.props.onChange(event.target.value);
               }
               if (this.props.onChange && event && event.target && event.target.value === '') {
                 this.props.onChange('');
               }
             }} />
    </div>
  }

  render() {
    return this.render_input_component();
  }
}
