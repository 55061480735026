import React from "react";
import '../css/default.css';
import { Player } from '@lottiefiles/react-lottie-player';

export default class Spinner extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render_default_color = () => {
    if (!this.props.color) {
      let style = this.props.style || {};
      return <Player autoplay loop src={ require('../animations/blue-spinner.json') } style={{ height: '20px', width: '20px', ...style }} />
    }
  }

  render_white_color = () => {
    if (this.props.color === 'white') {
      let style = this.props.style || {};
      return <Player autoplay loop src={ require('../animations/white-spinner.json') } style={{ height: '20px', width: '20px', ...style }} />
    }
  }

  render() {
    return (
      <div className="flex justify-center items-center">

        { this.render_white_color()   }
        { this.render_default_color() }

      </div>
    );
  }
}
