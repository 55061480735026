import { Routes }             from '../navigation';
import { getItem, setItem }   from '../Storage';
import ConsultationController from '../controllers/consultationController';

let navigate_to_consultation = async (navigate, called_from_intake) => {
  let partner              = getItem('partner');
  let care_consultation    = getItem('care_consultation');
  let care_consultation_id = getItem('care_consultation_id');
  let intake_disabled      = partner && partner.intake_questions_disabled && partner.intake_questions_disabled === true ? true : false;

  let is_video       = care_consultation.type === 'VIDEO';
  let is_live_video  = care_consultation.type === 'LIVE_VIDEO';
  let is_live_chat   = care_consultation.type === 'CHAT' && !care_consultation.is_async;
  let is_async_chat  = care_consultation.type === 'CHAT' && care_consultation.is_async === true;
  let is_training    = care_consultation && care_consultation.is_training ? true : false;

  /*
  if (is_training) {
    navigate(Routes.AVAILABILITY);
    return;
  }
  */

  if (!intake_disabled && !called_from_intake) {
    navigate(Routes.INTAKE);
    return;
  }

  if (is_video || is_training) {
    navigate(Routes.AVAILABILITY)
  }

  if (is_live_video) {
    assign_live_video_and_navigate(care_consultation_id, navigate);
  }

  if (is_async_chat) {
    assign_async_chat_and_navigate(care_consultation_id, navigate);
  }

  if (is_live_chat) {
    let providers_available = false;
    let practice_id         = getItem('practice_id');
    let partner_id          = getItem('partner_id');
    let referral_code       = getItem('referral_code');
    let available_response  = await ConsultationController.getOnlineChatProviders({ practice_id, partner_id });
        providers_available = available_response && available_response.available_provider_count && available_response.available_provider_count > 0 ? true : false;

    if (providers_available) {
      assign_live_chat_and_navigate(care_consultation_id, navigate);
    } else {
      let care_consultation_update = { care_consultation_id: care_consultation_id, is_async: true, referral_code: referral_code }
      let update_response          = await ConsultationController.editCareConsultation(care_consultation_update);
      let updated_consult          = update_response && update_response.success && update_response.data && update_response.data.care_consultation ? update_response.data.care_consultation : {};
      if (updated_consult && updated_consult._id) {
        setItem('care_consultation', updated_consult);
        assign_async_chat_and_navigate(care_consultation_id, navigate);
      }
    }
  }

}

let assign_live_chat_and_navigate  = async (care_consultation_id, navigate) => {
  let consult_partner_codes  = get_partner_and_consultation_code();
  let request_data           = { care_consultation_id: care_consultation_id };
  let assign_live_chat_res   = await ConsultationController.assignLiveChat(request_data);
  let updated_consultation   = assign_live_chat_res && assign_live_chat_res.success && assign_live_chat_res.data && assign_live_chat_res.data.care_consultation ? assign_live_chat_res.data.care_consultation : {};

  if (assign_live_chat_res && assign_live_chat_res.success && updated_consultation && updated_consultation._id) {
    setItem('care_consultation', updated_consultation);
    navigate(Routes.CHAT + '/' + consult_partner_codes.partner_code + '-' + consult_partner_codes.care_consultation_code);
  }
}

let assign_async_chat_and_navigate = async (care_consultation_id, navigate) => {
  let consult_partner_codes = get_partner_and_consultation_code();
  let request_data          = { care_consultation_id: care_consultation_id };
  let assign_async_chat_res = await ConsultationController.assignAsyncChat(request_data);
  let updated_consultation  = assign_async_chat_res && assign_async_chat_res.success && assign_async_chat_res.data && assign_async_chat_res.data.care_consultation ? assign_async_chat_res.data.care_consultation : {};

  if (assign_async_chat_res && assign_async_chat_res.success && updated_consultation && updated_consultation._id) {
    setItem('care_consultation', updated_consultation);
    navigate(Routes.CHAT + '/' + consult_partner_codes.partner_code + '-' + consult_partner_codes.care_consultation_code);
  }
}

let assign_live_video_and_navigate = async (care_consultation_id, navigate) => {
  let consult_partner_codes  = get_partner_and_consultation_code();
  let request_data           = { care_consultation_id: care_consultation_id };
  let assign_live_video_res  = await ConsultationController.assignLiveVideo(request_data);
  let updated_consultation   = assign_live_video_res && assign_live_video_res.success && assign_live_video_res.data && assign_live_video_res.data.care_consultation ? assign_live_video_res.data.care_consultation : {};

  if (assign_live_video_res && assign_live_video_res.success && updated_consultation && updated_consultation._id) {
    setItem('care_consultation', updated_consultation);
    navigate(Routes.LIVE_VIDEO + '/' + consult_partner_codes.partner_code + '-' + consult_partner_codes.care_consultation_code);
  }
}

let get_partner_and_consultation_code = () => {
  let care_consultation      = getItem('care_consultation');
  let partner                = getItem('partner');
  let care_consultation_code = care_consultation && care_consultation.code ? care_consultation.code : '';
  let partner_code           = partner && partner.code ? partner.code : '';
  return { partner_code: partner_code.toUpperCase(), care_consultation_code: care_consultation_code.toUpperCase() }
}

export default {
  navigate_to_consultation
}
