import React from "react";
import { config } from '../config/index';
import { Player } from '@lottiefiles/react-lottie-player';
import { DateUtils, StringUtils } from '../utils';
import { Button, Screen, Text, Spinner, Line, Loading } from '../components';
import { IntroSlides, Header, SignIn, CareSelection, Feedback } from '../containers';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import AuthController         from '../controllers/authController';
import UserController         from '../controllers/userController';
import UtilitiesController    from '../controllers/utilitiesController';
import ConsultationController from '../controllers/consultationController';

export default class VideoLiveScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      partner_code: '',
      care_consultation_code: '',
      ten_sec_timer: null,
      display_sign_in: true,
      code: '',
      care_consultation_id: '',
      care_consultation: {},
      appointment: {},
      video_session: {},
      video_session_codes: {},
      is_canceled: false,
      is_resolved: false,
      display_sign_in: false,
      display_no_access: false,
      loading_screen: false
    };
  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });

    let partner_code               = this.get_url_partner_code();
    let care_consultation_code     = this.get_url_care_consultation_code();
    let saved_care_consultation_id = getItem('care_consultation_id');
    let ten_sec_timer              = setInterval(this._onEveryTenSeconds, 10000);

    if (partner_code && care_consultation_code && !saved_care_consultation_id) {
      this.setState({ partner_code: partner_code, care_consultation_code: care_consultation_code });
      this.pull_consultation_id_from_code(partner_code, care_consultation_code);
    }

    if (saved_care_consultation_id) {
      this.pull_latest_care_consultation_data();
    }

    await DateUtils.delay(2600)

    this.setState({ ten_sec_timer: ten_sec_timer, loading_screen: false });
  }

  componentWillUnmount = () => {
    clearInterval(this.state.ten_sec_timer)
  }

  render_sign_in = () => {
    return (
      <Screen>
        <Header display_back_button={false} />
        <SignIn success_action={ () => { this.pull_consultation_id_from_code() }}/>
      </Screen>
    );
  }

  render_no_access = () => {
    return (
      <Screen>
        <Header display_back_button={false} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
          <Text style={{ fontWeight: 'bold', fontSize: 16 }}>You do not have access to this consultation.</Text>
        </div>
      </Screen>
    );
  }

  render_join_video_component = () => {
    let video_session       = this.state.video_session;
    let video_session_codes = this.state.video_session_codes;
    let provider_name       = this.state.care_consultation && this.state.care_consultation.provider ? StringUtils.displayName(this.state.care_consultation.provider) : '';
    let session_number      = video_session       && video_session.session_number              ? video_session.session_number                            : '';
    let access_code         = video_session_codes && video_session_codes.universal_access_code ? video_session_codes.universal_access_code.toLowerCase() : '';
    let video_url           = config.VIDEO_BASE_URL + '/' + session_number + '/' + access_code;

    if (this.state.provider_joined && !this.state.is_resolved) {

      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 25 }}>

        <span className='fas fa-check-circle' style={{ fontSize: 45, color: '#27AE60' }} />
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 20, marginTop: 5 }}>Provider has entered the room</Text>

        <div className='shadow-[0_10px_60px_1px_rgba(0,0,0,0.04)]' style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', width: 350, borderRadius: 15, padding: 20, paddingTop: 12, borderColor: '#EFEFEF', borderWidth: 2 }}>
          <Text style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>{ 'Video Call Info' }</Text>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Provider Name</Text>
            <Text style={{ color: 'grey', fontSize: 14 }}>{ provider_name }</Text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 5, marginBottom: 5 }}>
            <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Session Number</Text>
            <Text style={{ color: 'grey', fontSize: 14 }}>{ session_number }</Text>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Access Code</Text>
            <Text style={{ color: 'grey', fontSize: 14 }}>{ access_code.toUpperCase() }</Text>
          </div>
          <Button title='Join Video Call'
                  style={{ marginTop: 15, height: 50 }}
                  titleStyle={{ fontSize: 14 }}
                  onClick={ () => {
                    window.open(video_url, '_blank');
                  }} />
        </div>
      </div>
    }
  }

  render_loading_component = () => {
    if (!this.state.provider_joined && !this.state.is_resolved) {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Player autoplay loop src={ require('../animations/dog-walking.json') } style={{ height: '180px', width: '180px' }} />
        <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>Waiting for provider...</Text>
        <Text style={{ textAlign: 'center', fontSize: 14, color: '#6F767E' }}>Please remain on this screen, leaving the screen will end the Live Video session.</Text>
      </div>
    }
  }

  render_resolved_component = () => {
    if (this.state.is_resolved) {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span className='fas fa-check-circle' style={{ fontSize: 45, color: '#27AE60' }} />
        <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, marginBottom: 20, marginTop: 5 }}>Live Video consultation has ended.</Text>
      </div>
    }
  }

  render_live_section = () => {
    return <div>
      { this.render_join_video_component() }
      { this.render_loading_component()    }
      { this.render_resolved_component()   }
    </div>
  }

  render_feedback = () => {

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Feedback' />
          <Feedback skip_feedback_action={ () => { this.setState({ hide_feedback: true }) }} />
        </div>
      </Screen>
    )
  }

  render_screen_loading = (screen_title) => {
    return <Screen>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        <div style={{ height: 140 }} />
        <Loading type='bored_cat' title='Loading Live Video...' />
      </div>
    </Screen>
  }

  render() {
    if (this.state.loading_screen) {
      return this.render_screen_loading();
    }

    if (this.state.display_sign_in) {
      return this.render_sign_in();
    }

    if (this.state.display_no_access) {
      return this.render_no_access();
    }

    if (this.state.display_feedback && this.state.hide_feedback !== true) {
      return this.render_feedback();
    }

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Live Video' />
          { this.render_live_section() }
        </div>
      </Screen>
    );
  }

  pull_latest_care_consultation_data = async () => {
    let partner               = getItem('partner');
    let care_consultation_id  = getItem('care_consultation_id');
    let care_consultation_res = await ConsultationController.getCareConsultationDetails(care_consultation_id);

    let care_consultation     = care_consultation_res && care_consultation_res.care_consultation_details ? care_consultation_res.care_consultation_details : {};
    let appointment           = care_consultation_res && care_consultation_res.appointment         ? care_consultation_res.appointment         : {};
    let video_session         = care_consultation_res && care_consultation_res.video_session       ? care_consultation_res.video_session       : {};
    let video_session_codes   = care_consultation_res && care_consultation_res.video_session_codes ? care_consultation_res.video_session_codes : {};

    let is_canceled           = care_consultation && care_consultation.status && care_consultation.status === 'CANCELED' ? true : false;
    let is_resolved           = care_consultation && care_consultation.status && care_consultation.status === 'RESOLVED' ? true : false;
    let provider_joined       = care_consultation.live_provider_joined === true ? true : false;

    let signed_in_user_id     = getItem('user_id');
    let client_id             = care_consultation && care_consultation.client_id ? care_consultation.client_id : '';
    let display_no_access     = signed_in_user_id && client_id && client_id === signed_in_user_id ? false : true;
    let display_feedback      = care_consultation && care_consultation._id && !care_consultation.feedback_data && is_resolved ? true : false;
        display_feedback      = partner           && partner.feedback_enabled === false ? false : display_feedback;

    if (care_consultation) {
      let is_video_scheduled = care_consultation && (care_consultation.status === 'SCHEDULED' || care_consultation.status === 'ACTIVE' || care_consultation.status === 'IN_PROGRESS');
      setItem('is_video_scheduled', is_video_scheduled);
    }

    this.setState({ care_consultation_id: care_consultation_id, care_consultation: care_consultation, appointment: appointment, video_session: video_session, video_session_codes: video_session_codes, is_canceled: is_canceled, is_resolved: is_resolved, display_sign_in: false, display_no_access: display_no_access, provider_joined: provider_joined, display_feedback: display_feedback });
  }

  pull_consultation_id_from_code = async (partner_code, care_consultation_code) => {
    let token         = getItem('token');
    let user_id       = getItem('user_id');
    let partner       = getItem('partner');
    let is_signed_in  = token && user_id ? true : false;
    let consult_code  = this.state.care_consultation_code || care_consultation_code;
    let saved_partner = partner && partner._id ? true : false;

    if (is_signed_in) {
      let code_consultation_res = await ConsultationController.getCareConsultationDetailsFromCode(consult_code);
      let care_consultation_id  = code_consultation_res && code_consultation_res.data && code_consultation_res.data.care_consultation && code_consultation_res.data.care_consultation._id ? code_consultation_res.data.care_consultation._id : '';
      setItem('care_consultation_id', care_consultation_id);
      this.pull_latest_care_consultation_data();
    }

    if (!is_signed_in && saved_partner) {
      this.setState({ display_sign_in: true, code: consult_code });
    }

    if (!is_signed_in && !saved_partner) {
      this.pull_partner_from_code(partner_code);
    }
  }

  pull_partner_from_code = async (partner_code) => {
    let partner = await UserController.getPartnerDetails({ code: partner_code });
    if (partner) {
      setItem('partner', partner);
      setItem('partner_name', partner.name);
      this.pull_consultation_id_from_code();
    }
  }

  get_url_partner_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0] : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1] : '';

    return partner_code;
  }

  get_url_care_consultation_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0] : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1] : '';

    return consult_code;
  }

  _onEveryTenSeconds = async () => {
    let care_consultation_id = getItem('care_consultation_id');

    if (care_consultation_id) {
      let ping_response = await ConsultationController.setLatestLivePingTime({ care_consultation_id });
    }

    if (care_consultation_id) {
      let care_consultation_response = await ConsultationController.getCareConsultationDetails(care_consultation_id);
      let care_consultation          = care_consultation_response.care_consultation_details;

      if (care_consultation) {
        let provider_joined = care_consultation.live_provider_joined === true ? true : false;
        let is_resolved     = care_consultation && care_consultation.status && care_consultation.status === 'RESOLVED' ? true : false;

        this.setState({ provider_joined: provider_joined, is_resolved: is_resolved });
      }
    }
  }

}
