import React    from "react";
import { Text } from '../components';
import '../css/default.css';

import united_states_flag from '../images/united-states.png';
import canada_flag        from '../images/canada.png';
import worldwide          from '../images/worldwide.png';

export default class PhoneInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_country: 'united_states',
    };
  }

  render_label = (label_text) => {
    let preview_phone_number = this.get_preview_phone_number();
    return <div className='flex-container-row' style={{ paddingLeft: 0, marginBottom: 8, justifyContent: 'space-between' }}>
      <Text style={{ fontSize: 14, paddingLeft: 4, fontWeight: 'bold', color: '#6F767E' }}>{ label_text }</Text>
      <Text style={{ fontSize: 14, paddingLeft: 4, fontWeight: 'bold', color: '#6F767E' }}>{ preview_phone_number }</Text>
    </div>
  }

  render_country_code = () => {
    let selected_country = this.state.selected_country;

    switch (selected_country) {
      case 'united_states':
        return <div className='flex-container-row input-pointer' style={{ width: 100, marginTop: 5, height: 44, backgroundColor: '#f2f4f6', marginRight: 5, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onClick={ () => { this.toggle_country() }}>
          <img src={ united_states_flag } style={{ height: 33 }} alt="Flag Icon" />
          <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 8 }}>{ '+1' }</Text>
        </div>
        break;
      case 'canada':
        return <div className='flex-container-row input-pointer' style={{ width: 100, marginTop: 5, height: 44, backgroundColor: '#f2f4f6', marginRight: 5, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onClick={ () => { this.toggle_country() }}>
          <img src={ canada_flag } style={{ height: 33 }} alt="Flag Icon" />
          <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 8 }}>{ '+1' }</Text>
        </div>
      case 'other':
        return <div className='flex-container-row input-pointer' style={{ width: 100, marginTop: 5, height: 44, backgroundColor: '#f2f4f6', marginRight: 5, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onClick={ () => { this.toggle_country() }}>
          <img src={ worldwide  } style={{ height: 28 }} alt="Flag Icon" />
          <Text style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 8, marginRight: 1.2 }}>{ '+' }</Text>
        </div>
      default:

    }
  }

  render_input_component = () => {
    let input_type      = this.props.type || 'tel';
    let input_label     = this.props.label;
    let passed_style    = this.props.style || {};
    let container_style = this.props.container_style || {};
    let placeholder     = this.props.placeholder || '';
    let value           = this.props.value || '';
    let cleaned_number  = this.clean_number(value);

    return <div style={{ ...container_style }}>
      { input_label ? this.render_label(input_label) : null }
      <div className='flex-container-row' style={{ alignItems: 'center' }}>
      { this.render_country_code() }
      <input type={input_type}
             value={cleaned_number}
             disabled={this.props.disabled === true}
             style={{ fontFamily: 'Manrope', fontSize: 16, borderWidth: 2, ...passed_style }}
             placeholder={ placeholder }
             className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-sky-300 focus:bg-white focus:ring-0"
             onChange={ (event) => {
               if (this.props.onChange && event && event.target && event.target.value && this.is_a_number(event.target.value)) {
                 let coded_number = this.add_number_prefix(event.target.value)
                 this.props.onChange(coded_number);
               }
               if (this.props.onChange && event && event.target && event.target.value === '') {
                 this.props.onChange('');
               }
             }} />
        </div>
    </div>
  }

  // <PhoneInput label='Phone Number' style={{ width: 300 }} value={this.state.phone_number} onChange={ (phone_number) => { this.setState({ phone_number: phone_number }) }} />

  render() {
    return this.render_input_component();
  }

  get_preview_phone_number = () => {
    let preview_phone_number = this.props.value || '';
        preview_phone_number =  this.clean_number(preview_phone_number);

    let add_plus_one         = this.state.selected_country === 'united_states' || this.state.selected_country === 'canada';
    let number_length        = preview_phone_number ? preview_phone_number.length : 0;
    let formatted_number     = '';

    if (add_plus_one && number_length > 0) {
      if (number_length < 3) {
        formatted_number = '+1 ' + '(' + preview_phone_number + ')';
      }
      if (number_length === 3) {
        formatted_number = '+1 ' +  '(' + preview_phone_number + ')';
      }
      if (number_length > 3 && number_length < 7) {
        formatted_number = '+1 ' +  '(' + preview_phone_number[0] + preview_phone_number[1] +  preview_phone_number[2] + ') ' + preview_phone_number.substring(3);
      }
      if (number_length > 6 && number_length < 11) {
        formatted_number = '+1 ' +  '(' + preview_phone_number[0] + preview_phone_number[1] +  preview_phone_number[2] + ') ' + preview_phone_number[3] + preview_phone_number[4] + preview_phone_number[5] + ' ' + preview_phone_number.substring(6);
      }
      if (number_length > 10) {
        formatted_number = '+1' + preview_phone_number;
      }
    } else {
      formatted_number = '+' + preview_phone_number;
    }

    if (number_length === 0) {
      formatted_number = '';
    }

    return formatted_number;
  }

  is_a_number = (input_text) => {
    let last_character = input_text && input_text.length > 0 ? input_text[input_text.length - 1] : '';
    if (last_character === '0' || last_character === '1' || last_character === '2' || last_character === '3' || last_character === '4' || last_character === '5' || last_character === '6' || last_character === '7' || last_character === '8' || last_character === '9') {
      return true;
    }
    return false;
  }

  toggle_country = () => {
    let current_country = this.state.selected_country;
    let new_country     = current_country === 'united_states' ? 'canada'        : 'other';
        new_country     = current_country === 'other'         ? 'united_states' : new_country;

    if (this.props.value && this.props.value.length > 1) {
      let new_number   = this.clean_number(this.props.value);
      let coded_number = this.add_number_prefix(new_number)
      this.props.onChange(coded_number);
    }

    this.setState({ selected_country: new_country });
  }

  clean_number = (number) => {
    let cleaned         = '';
    let remove_plus_one = number && number.length > 2 && (this.state.selected_country === 'united_states' || this.state.selected_country === 'canada');
    let remove_plus     = !remove_plus_one && number && number.length > 1;

    if (remove_plus_one) {
      cleaned = number.substring(2);
    }

    if (remove_plus) {
      cleaned = number.substring(1);
    }

    return cleaned;
  }

  add_number_prefix = (number) => {
    let add_plus_one = this.state.selected_country === 'united_states' || this.state.selected_country === 'canada';
    if (add_plus_one) {
      return '+1' + number;
    }
    return '+' + number;
  }
}
