import React from "react";
import '../css/default.css';

export default class Text extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {

    return (
        <span className='' style={{ fontFamily: 'Manrope', ...this.props.style }}>{ this.props.children }</span>
    );
  }
}
