export const config = {
    VIDEO_BASE_URL: "https://video.teletails.com",

    // LOCAL
    // apiURL: "http://localhost:3000/data",
    // CHAT_URL: "http://localhost:8000",
    // NOTIF_URL: "http://localhost:4000",
    // PARTNER_CODE: 'hj5wlc',
    // DEFAULT_PARTNER_CODE: '187m3d',

    // DEVELOPMENT
    // apiURL: "https://dev-elb-api.teletails.com/data",
    // CHAT_URL:  "https://dev-elb-chat.teletails.com",
    // NOTIF_URL: "https://dev-elb-chat.teletails.com",
    // PARTNER_CODE: 'hj5wlc',
    // DEFAULT_PARTNER_CODE: '187m3d',

    // apiURL: "http://192.168.12.209:3000/data",
    // CHAT_URL: "http://192.168.12.209:8000",
    // NOTIF_URL: "http://192.168.12.209:4000",

    // apiURL:    'https://dev-elb-api.teletails.com/data',
    // CHAT_URL:  'https://dev-elb-chat.teletails.com',
    // NOTIF_URL: 'https://dev-elb-chat.teletails.com',

    // PRODUCTION HILLS
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'lmctec',

    // PRODUCTION ZESTY
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: '9bnfrj',

    // PRODUCTION TELETAILSPROD
    // apiURL:    'https://prod-elb-api.teletails.com/data',
    // CHAT_URL:  'https://prod-elb-chat.teletails.com',
    // NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    // DEFAULT_PARTNER_CODE: 'tele18',
    // PARTNER_CODE: 'l1w57r',

    // PRODUCTION GENERIC
    apiURL:    'https://prod-elb-api.teletails.com/data',
    CHAT_URL:  'https://prod-elb-chat.teletails.com',
    NOTIF_URL: 'https://prod-elb-chat.teletails.com',
    DEFAULT_PARTNER_CODE: 'tele18'
};
