import React, { Component } from 'react';

class Line extends Component {

  render() {
    var defaultStyle = { backgroundColor: '#e7e7e7', height: 1, width: '100%'};
    var style = this.props.style;

    if (style) {
      Object.keys(style).forEach((key) => {
        defaultStyle[key] = style[key];
      })
    }

    if (this.props.hide) {
      return null;
    }

    return<div style={defaultStyle}></div>
  }

}

export default Line
