import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import { StringUtils, DateUtils }    from '../utils';
import { getItem, setItem }          from '../Storage';
import { Button, Text, Input, Line } from '../components';
import '../css/default.css';

export default class ChatMessage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {

  }

  return_text_content = (message) => {
    let message_text = message && message.content && message.content.text ? message.content.text : '';
    let is_sent      = message && message.is_sent   ? true   : false;
    let sender       = message.sender && message.sender.first_name ? message.sender : {};
    let time_string  = message.created_at ? DateUtils.getHoursString(message.created_at) +  ':'  + DateUtils.getMinutesString(message.created_at) + ' ' + DateUtils.getAMPMString(message.created_at) : '';
    let sender_name  = StringUtils.displayName(sender);
    return <div className='gradient-background' style={{ display: 'flex', flexDirection: 'column', height: '100%', background: is_sent ? '' : 'white', borderRadius: 10, maxWidth: 300, padding: 12, paddingLeft: 14, paddingTop: 10, borderWidth: 1, borderColor: is_sent ? 'white' : '#CFD0D7' }}>
      { !is_sent && sender_name ? <Text style={{ fontSize: 13, fontWeight: 'bold', color: '#6F767E', marginBottom: 5 }}>{ sender_name }</Text> : null }
      <Text style={{ color: is_sent ? 'white' : '#141415', fontSize: 15 }}>{ message_text }</Text>
      { time_string ? <Text style={{ color: is_sent ? 'white' : '#6F767E', fontSize: 12, marginTop: 5 }}>{ time_string }</Text> : null }
    </div>
  }

  return_image_content = (message) => {
    let image_url = message && message.content && message.content.url ? message.content.url : '';
    return <img style={{ height: 300, width: 'auto', maxWidth: 300, objectFit: 'contain', borderRadius: 5 }} src={image_url} />
  }

  return_video_content = (message) => {
    let video_url = message && message.content && message.content.url ? message.content.url : '';
    return <div>
      <video style={{ width: 250, height: 'auto', maxHeight: 300, backgroundColor: 'black', borderRadius: 10 }} controls>
        <source src={video_url} type="video/mp4" />
        Sorry, your browser doesn't support this video format.
      </video>
    </div>
  }

  return_pdf_content = (message) => {
    let pdf_url = message && message.content && message.content.url ? message.content.url : '';
    return <a href={pdf_url} target="_blank">
      <div className='universal-button' style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#e7e7e7', borderRadius: 10, maxWidth: 300, padding: 10, paddingLeft: 12, paddingTop: 8 }}>
        <div className='flex-container-row' style={{ alignItems: 'center' }}>
          <span className='fas fa-file-pdf' style={{ color: '#ff6262', fontSize: 30, marginRight: 10 }}></span>
          <div className='flex-container-column' style={{ marginRight: 5}}>
            <span style={{ fontSize: 13, color: '#474747' }}>PDF Attachment</span>
          </div>
        </div>
      </div>
    </a>
  }

  return_bubble_message = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#f3f3f3', borderRadius: 10, maxWidth: 300, borderBottomLeftRadius: 0, width: 60 }}>
      <Player autoplay loop src={ require('../animations/grey-typing-bubbles.json') } style={{ height: '40px', width: '40px' }} />
    </div>
  }

  render_message_content = (message) => {
    switch (message.type) {
      case 'BUBBLE':
        return this.return_bubble_message(message)
        break;
      case 'TEXT':
        return this.return_text_content(message)
        break;
      case 'IMAGE':
        return this.return_image_content(message);
        break;
      case 'VIDEO':
        return this.return_video_content(message);
        break;
      case 'PDF':
        return this.return_pdf_content(message);
        break;
      default:

    }
  }

  render_received_message = (message) => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '0 0 auto', width: 250, marginBottom: 8, width: '100%', paddingLeft: 10 }}>
      { this.render_message_content(message) }
    </div>
  }

  render_sent_message = (message) => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flex: '0 0 auto', width: 250, marginBottom: 8, width: '100%', paddingRight: 10 }}>
      { this.render_message_content(message) }
    </div>
  }

  render_message = () => {
    let message = this.props.message || {};
    let from_id = message.from;
    let user_id = getItem('user_id');
    let is_sent = from_id === user_id;
        message = { ...message, is_sent: is_sent }

    if (is_sent) {
      return this.render_sent_message(message);
    } else {
      return this.render_received_message(message);
    }
  }

  render() {

    return (
      <div>
        { this.render_message()   }
      </div>
    );
  }
}
