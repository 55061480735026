import React from "react";
import '../css/default.css';
import { Input, Text } from '../components';

export default class CodeInput extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    let placeholder = this.props.placeholder || '';
    let hide_label  = this.props.hide_label ? true : false;
    let autofocus   = this.props.autofocus  ? true : false;

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      { !hide_label ? <Text>Code Input</Text> : null }
      <Input type='tel'
             placeholder={ placeholder }
             value={this.props.value}
             style={{ width: 100, textAlign: 'center', ...this.props.style }}
             container_style={{  }}
             onChange={ (text_input) => {
               this.props.onChange(text_input);
             }} />
    </div>
  }
}
