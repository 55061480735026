import React from "react";
import 'add-to-calendar-button';
import { atcb_action } from "add-to-calendar-button";
import { config } from '../config/index';
import { DateUtils, StringUtils } from '../utils';
import { Button, Screen, Text, Spinner, Line, Loading } from '../components';
import { IntroSlides, Header, SignIn, CareSelection, Feedback } from '../containers';
import { getItem, setItem }   from '../Storage';
import { Routes }             from '../navigation';
import AuthController         from '../controllers/authController';
import UserController         from '../controllers/userController';
import UtilitiesController    from '../controllers/utilitiesController';
import ConsultationController from '../controllers/consultationController';

export default class VideoAppointmentScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      care_consultation_code: '',
      partner_code: '',
      display_sign_in: false,
      care_consultation: {},
      appointment: {},
      video_session: {},
      video_session_codes: {},
      is_canceled: false,
      is_resolved: false,
      loading_cancel_appointment: false,
      hide_feedback: false,
      loading_screen: false
    };

    this.consultation_socket = null;
  }

  consultation_socket_connect(care_consultation_id) {
    return UtilitiesController.getSocket({ name: `consultation_${care_consultation_id}`, query: 'notification=' + care_consultation_id, notification: true })
  }

  connect_sockets = (care_consultation_id) => {

    if (!this.consultation_socket) {
      this.consultation_socket = this.consultation_socket_connect(care_consultation_id);
    }

    this.consultation_socket.on('care_consultation_updated', async () => {
      this.pull_latest_care_consultation_data();
    });

  }

  componentDidMount = async () => {
    this.setState({ loading_screen: true });

    let partner_code               = this.get_url_partner_code();
    let care_consultation_code     = this.get_url_care_consultation_code();
    let saved_care_consultation_id = getItem('care_consultation_id');
    let ten_sec_timer              = setInterval(this._onEveryTenSeconds, 30000);

    this.connect_sockets(saved_care_consultation_id);

    if (partner_code && care_consultation_code && !saved_care_consultation_id) {
      this.setState({ partner_code: partner_code, care_consultation_code: care_consultation_code });
      this.pull_consultation_id_from_code(partner_code, care_consultation_code);
    }

    if (saved_care_consultation_id) {
      this.pull_latest_care_consultation_data();
    }

    await DateUtils.delay(2600)

    this.setState({ loading_screen: false });
  }

  componentWillUnmount = () => {
    clearInterval(this.state.ten_sec_timer)
  }

  render_appointment_details = () => {
    let apt_time        = this.state.appointment && this.state.appointment.time ? this.state.appointment.time : '';
    let time_string     = '';
    let date_string     = '';
    let is_canceled     = this.state.is_canceled;
    let is_resolved     = this.state.is_resolved;

    let client          = this.state.care_consultation && this.state.care_consultation.client ? this.state.care_consultation.client : {};
    let client_name     = client ?  StringUtils.displayName(client) : '';
    let client_email    = client ?  client.email : '';
    let client_phone    = client && client.phone_number ? StringUtils.displayCodePhoneNumber(client.phone_number) : '';

    if (apt_time) {
      time_string = DateUtils.getHoursString(apt_time)  +  ':'  + DateUtils.getMinutesString(apt_time) + ' ' + DateUtils.getAMPMString(apt_time);
      date_string = DateUtils.getShortWeekday(apt_time) + ' - ' + DateUtils.getShortMonth(apt_time)    + ' ' + DateUtils.getDateNumber(apt_time);
    }

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: is_canceled || is_resolved ? 0 : 10 }}>

      { !is_canceled ? null : <Text style={{ color: 'red',     fontSize: 16, marginBottom: 8 }}>Canceled</Text> }
      { !is_resolved ? null : <Text style={{ color: '#27AE60', fontSize: 16, marginBottom: 8 }}>Completed</Text> }

      { is_canceled  ? null : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                  <span className='fas fa-calendar-alt' style={{ color: '#4FC4EF', fontSize: 20, width: 30 }} />
                                  <Text style={{ fontSize: 16, color: '#1C2123' }}>{ date_string ? date_string : '' }</Text>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                                  <span className='fas fa-clock' style={{ color: '#4FC4EF', fontSize: 20, width: 30 }} />
                                  <Text style={{ fontSize: 16, color: '#1C2123' }}>{ time_string ? time_string : '' }</Text>
                                </div>
                                <button id="my-default-button" className='button-default' style={{ height: 40, width: 135, padding: 0, fontSize: 11 }} onClick={ () => {
                                  let partner      = getItem('partner');
                                  let partner_name = partner && partner.display_name ? partner.display_name : 'Pet';
                                  let appt_name    = partner_name + ' Video Appointment';
                                  let video_url    = this.get_video_call_url();
                                  let description  = 'Appointment Video Call Link: ' + video_url;

                                  let date_str     = DateUtils.getDateNumber(apt_time)  && DateUtils.getDateNumber(apt_time).toString()  && DateUtils.getDateNumber(apt_time).toString().length < 2  ? '0' + DateUtils.getDateNumber(apt_time).toString()  : DateUtils.getDateNumber(apt_time).toString();
                                  let month_str    = DateUtils.getMonthNumber(apt_time) && DateUtils.getMonthNumber(apt_time).toString() && DateUtils.getMonthNumber(apt_time).toString().length < 2 ? '0' + DateUtils.getMonthNumber(apt_time).toString() : DateUtils.getMonthNumber(apt_time).toString();
                                  let start_date   = DateUtils.getYear(apt_time) + '-' + month_str + '-' + date_str;

                                  let start_hours  = DateUtils.getMilitaryHoursString(apt_time)      && DateUtils.getMilitaryHoursString(apt_time).length < 2      ? '0' + DateUtils.getMilitaryHoursString(apt_time) : DateUtils.getMilitaryHoursString(apt_time);
                                  let start_mins   = DateUtils.getMinutesString(apt_time).toString() && DateUtils.getMinutesString(apt_time).toString().length < 2 ? '0' + DateUtils.getMinutesString(apt_time)       : DateUtils.getMinutesString(apt_time);
                                  let start_time   = start_hours + ':' + start_mins;

                                  let start_date_obj = new Date(apt_time);
                                  let end_time_date  = new Date(start_date_obj.getTime() + 15 * 60000);
                                  let end_time_str   = end_time_date.toString();

                                  let end_hours  = DateUtils.getMilitaryHoursString(end_time_str)      && DateUtils.getMilitaryHoursString(end_time_str).length < 2      ? '0' + DateUtils.getMilitaryHoursString(end_time_str) : DateUtils.getMilitaryHoursString(end_time_str);
                                  let end_mins   = DateUtils.getMinutesString(end_time_str).toString() && DateUtils.getMinutesString(end_time_str).toString().length < 2 ? '0' + DateUtils.getMinutesString(end_time_str)       : DateUtils.getMinutesString(end_time_str);
                                  let end_time   = end_hours + ':' + end_mins;

                                  const config = {
                                    name: appt_name,
                                    description: description,
                                    startDate: start_date,
                                    startTime: start_time,
                                    endTime: end_time,
                                    options: ["Google|Google Calendar", "Apple", "Outlook.com"],
                                    timeZone: "America/New_York"
                                  };
                                  const button = document.getElementById('my-default-button');
                                  atcb_action(config, button)
                                }}>Add To Calendar</button>
                              </div> }

      <div style={{ height: 15 }} />

      <div style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderRadius: 12, borderColor: '#EFEFEF', padding: 20, paddingTop: 12, paddingBottom: 15, backgroundColor: 'white' }}>
        <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#1A1D1F' }}>{ client_name  }</Text>
        <Text style={{ fontSize: 14, color: '#6F767E' }}>{ client_email }</Text>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
          <span className='fas fa-mobile-alt' style={{ color: '#4FC4EF', fontSize: 16, marginRight: 8 }} />
          <Text style={{ fontSize: 14 }}>{ client_phone }</Text>
        </div>
      </div>

    </div>
  }

  render_join_details = () => {
    let apt_time            = this.state.appointment && this.state.appointment.time ? this.state.appointment.time : '';
    let video_session       = this.state.video_session;
    let video_session_codes = this.state.video_session_codes;
    let enable_join_btn     = false;
    let button_title        = 'Join Video Call';

    let provider_name       = this.state.care_consultation && this.state.care_consultation.provider ? StringUtils.displayName(this.state.care_consultation.provider) : '';
    let session_number      = video_session       && video_session.session_number              ? video_session.session_number                            : '';
    let access_code         = video_session_codes && video_session_codes.universal_access_code ? video_session_codes.universal_access_code.toLowerCase() : '';

    if (apt_time) {
      let current_date  = new Date();
      let apt_date_obj  = new Date(apt_time);
      let min_diff      = (apt_date_obj.getTime() - current_date.getTime()) / 60000;
      enable_join_btn   = min_diff < 5 ? true : false;
    }

    enable_join_btn     = this.state.is_canceled || this.state.is_resolved ? false : enable_join_btn;
    button_title        = this.state.is_canceled ? 'Canceled'  : button_title;
    button_title        = this.state.is_resolved ? 'Completed' : button_title;


    let video_url       = config.VIDEO_BASE_URL + '/' + session_number.toUpperCase() + '/' + access_code.toUpperCase();

    return <div style={{ display: 'flex', flexDirection: 'column', borderWidth: 2, borderRadius: 12, borderColor: '#EFEFEF', padding: 20, paddingTop: 15, paddingBottom: 15, backgroundColor: 'white', marginTop: 15, alignItems: 'flex-start' }}>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>{ 'Video Call Info' }</Text>
        <Line style={{ marginBottom: 10 }}/>

        <div style={{ alignSelf: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Provider Name</Text>
          <Text style={{ color: 'grey', fontSize: 14 }}>{ provider_name }</Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 5, marginBottom: 5 }}>
          <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Session Number</Text>
          <Text style={{ color: 'grey', fontSize: 14 }}>{ session_number }</Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
          <Text style={{ color: '#4c4c4c', fontSize: 12, fontWeight: 'bold', width: 120 }}>Access Code</Text>
          <Text style={{ color: 'grey', fontSize: 14 }}>{ access_code.toUpperCase() }</Text>
        </div>
        </div>

        <Button title='Join Video Call'
                disabled={true}
                style={{ width: 300, marginTop: 20, marginBottom: 5, alignSelf: 'center', boxShadow: 'none' }}
                titleStyle={{ fontSize: 16 }}
                onClick={ () => {
                  window.open(video_url, '_blank');
                }} />
    </div>
  }

  render_cancel_section = () => {
    if (this.state.is_canceled || this.state.is_resolved) {
      return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, paddingRight: 20, paddingLeft: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#141415', marginBottom: 8 }}>Can't make it to the call?</Text>
        <Button title='Cancel Appointment'
                loading={this.state.loading_cancel_appointment}
                grey={true}
                style={{ height: 55, width: 270 }}
                titleStyle={{ fontSize: 14, color: '#EE7488' }}
                onClick={ async () => {
                  this.setState({ loading_cancel_appointment: true });

                  let request_data = { care_consultation_id: this.state.care_consultation_id }
                  let cancel_res   = await ConsultationController.cancelVideoConsultation(request_data);

                  this.pull_latest_care_consultation_data();

                  this.setState({ loading_cancel_appointment: false });
                }}/>
      </div>
    </div>
  }

  render_sign_in = () => {
    return (
      <Screen>
        <SignIn success_action={ () => { this.pull_consultation_id_from_code() }}/>
      </Screen>
    );
  }

  render_no_access = () => {
    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title="Can't Access Consultation" subtitle='You do you not have access to this consultation.' />
        </div>
      </Screen>
    );
  }

  render_feedback = () => {

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Feedback' />
          <Feedback skip_feedback_action={ () => { this.setState({ hide_feedback: true }) }} />
        </div>
      </Screen>
    )
  }

  render_screen_loading = () => {
    return <Screen>
      <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
        <div style={{ height: 100 }} />
        <Loading type='calendar' title='Retrieving Appointments Details...' />
      </div>
    </Screen>
  }

  render() {
    if (this.state.loading_screen) {
      return this.render_screen_loading();
    }

    if (this.state.display_sign_in) {
      return this.render_sign_in();
    }

    if (this.state.display_no_access) {
      return this.render_no_access();
    }

    if (this.state.display_feedback && this.state.hide_feedback !== true) {
      return this.render_feedback();
    }

    return (
      <Screen>
        <div style={{ display: 'flex', flexDirection: 'column', width: '85%', alignSelf: 'center' }}>
          <Header title='Video Appointment Details' />
          { this.render_appointment_details() }
          { this.render_join_details()        }
          { this.render_cancel_section()      }
        </div>
      </Screen>
    );
  }

  pull_latest_care_consultation_data = async () => {
    let partner               = getItem('partner');
    let care_consultation_id  = getItem('care_consultation_id');
    let care_consultation_res = await ConsultationController.getCareConsultationDetails(care_consultation_id);

    let care_consultation     = care_consultation_res && care_consultation_res.care_consultation_details ? care_consultation_res.care_consultation_details : {};
    let appointment           = care_consultation_res && care_consultation_res.appointment         ? care_consultation_res.appointment         : {};
    let video_session         = care_consultation_res && care_consultation_res.video_session       ? care_consultation_res.video_session       : {};
    let video_session_codes   = care_consultation_res && care_consultation_res.video_session_codes ? care_consultation_res.video_session_codes : {};

    let is_canceled           = care_consultation && care_consultation.status && care_consultation.status === 'CANCELED' ? true : false;
    let is_resolved           = care_consultation && care_consultation.status && care_consultation.status === 'RESOLVED' ? true : false;

    let signed_in_user_id     = getItem('user_id');
    let client_id             = care_consultation && care_consultation.client_id ? care_consultation.client_id : '';
    let display_no_access     = signed_in_user_id && client_id && client_id === signed_in_user_id ? false : true;
    let display_feedback      = care_consultation && care_consultation._id && !care_consultation.feedback_data && is_resolved ? true : false;
        display_feedback      = partner           && partner.feedback_enabled === false ? false : display_feedback;

    if (care_consultation) {
      let is_video_scheduled = care_consultation && (care_consultation.status === 'SCHEDULED' || care_consultation.status === 'ACTIVE' || care_consultation.status === 'IN_PROGRESS');
      setItem('is_video_scheduled', is_video_scheduled);
    }

    this.setState({ care_consultation_id: care_consultation_id, care_consultation: care_consultation, appointment: appointment, video_session: video_session, video_session_codes: video_session_codes, is_canceled: is_canceled, is_resolved: is_resolved, display_sign_in: false, display_no_access: display_no_access, display_feedback: display_feedback });
  }

  pull_consultation_id_from_code = async (partner_code, care_consultation_code) => {
    let token         = getItem('token');
    let user_id       = getItem('user_id');
    let partner       = getItem('partner');
    let is_signed_in  = token && user_id ? true : false;
    let consult_code  = this.state.care_consultation_code || care_consultation_code;
    let saved_partner = partner && partner._id ? true : false;

    if (is_signed_in) {
      let code_consultation_res = await ConsultationController.getCareConsultationDetailsFromCode(consult_code);
      let care_consultation_id  = code_consultation_res && code_consultation_res.data && code_consultation_res.data.care_consultation && code_consultation_res.data.care_consultation._id ? code_consultation_res.data.care_consultation._id : '';
      setItem('care_consultation_id', care_consultation_id);
      this.pull_latest_care_consultation_data();
    }

    if (!is_signed_in && saved_partner) {
      this.setState({ display_sign_in: true, code: consult_code });
    }

    if (!is_signed_in && !saved_partner) {
      this.pull_partner_from_code(partner_code);
    }
  }

  pull_partner_from_code = async (partner_code) => {
    let partner = await UserController.getPartnerDetails({ code: partner_code });
    if (partner && partner._id) {
      setItem('partner', partner);
      setItem('partner_name', partner.name);
      this.pull_consultation_id_from_code();
    }
  }

  get_url_partner_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0] : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1] : '';

    return partner_code;
  }

  get_url_care_consultation_code = () => {
    let current_url  = window.location.href;
    let split_up     = current_url.split('/');
    let codes_arr    = split_up && split_up.length > 0 ? split_up[split_up.length - 1].split('-') : '';
    let partner_code = codes_arr && codes_arr[0] ? codes_arr[0] : '';
    let consult_code = codes_arr && codes_arr[1] ? codes_arr[1] : '';

    return consult_code;
  }

  get_video_call_url = () => {
    let video_session       = this.state.video_session;
    let video_session_codes = this.state.video_session_codes;
    let session_number      = video_session       && video_session.session_number              ? video_session.session_number                            : '';
    let access_code         = video_session_codes && video_session_codes.universal_access_code ? video_session_codes.universal_access_code.toLowerCase() : '';
    let video_url           = config.VIDEO_BASE_URL + '/' + session_number.toUpperCase() + '/' + access_code.toUpperCase();

    return video_url;
  }

  _onEveryTenSeconds = async () => {
    let care_consultation_id = getItem('care_consultation_id');

    if (care_consultation_id) {
      this.pull_latest_care_consultation_data();
    }
  }

}
