const routes = {
  HOME: '/',
  INTAKE: '/intake',
  CHAT: '/chat',
  PETS: '/pets',
  APPOINTMENT: '/appointment',
  AVAILABILITY: '/availability',
  LIVE_VIDEO: '/live'
}

export default routes;
