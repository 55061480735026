import React from "react";
import { Button, Text, Input, CodeInput, Error, CheckBox, PhoneInput } from '../components';
import { Header }              from '../containers';
import { getItem, setItem }    from '../Storage';
import UserController          from '../controllers/userController';
import AuthController          from '../controllers/authController';

import 'react-phone-input-2/lib/material.css'
import '../css/default.css';

export default class PhoneAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      phone_number: '',
      send_code_disabled: true,
      loading_send_code: false,
      loading_verify_code: false,
      display_code_input: false,
      error: '',
      tos_accepted: false
    };
  }

  componentDidMount = async () => {

  }

  render_title_description = (title, subtitle) => {
    return <Header title={ title } subtitle={ subtitle } />
  }

  render_phone_input = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
      { this.render_title_description('Phone Number', 'Enter the phone number in order to receive video call notifications and url.') }
      <div style={{ height: 10 }} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PhoneInput
          label='Phone Number'
          value={this.state.phone_number}
          onChange={ (phone_number) => {
            let phone_disabled = phone_number.length < 12 ? true : false;
            this.setState({ phone_number: phone_number, send_code_disabled: phone_disabled });
          }} />
      </div>
      <div style={{ height: 25 }} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CheckBox checked={ this.state.tos_accepted } onClick={ () => { this.setState({ tos_accepted: !this.state.tos_accepted }) }} />
        <span style={{ fontSize: 14, color: '#6F767E', marginLeft: 5 }}>By continuing, you agree to receive system notifications at the phone number provided.</span>
      </div>
      <div style={{ height: 25 }} />
      <Button title='Send Verification Code'
              disabled={this.state.send_code_disabled}
              loading={this.state.loading_send_code}
              style={{ height: 55, marginRight: 10, marginLeft: 10, width: 300, alignSelf: 'center' }}
              titleStyle={{ fontSize: 16 }}
              onClick={ async () => {
                this.setState({ loading_send_code: true });

                if (this.state.phone_number === '') {
                  this.setState({ loading_send_code: false, error: 'No Phone Number Entered' });
                  return;
                }

                if (!this.state.tos_accepted) {
                  this.setState({ loading_send_code: false, error: 'Please agree to receive system notifications by checking the box above.' });
                  return;
                }

                let partner            = this.props.partner || {};
                let user               = this.props.user    || {};
                let partner_name       = partner && partner.display_name ? partner.display_name : '';
                let host_name          = partner && partner.name         ? partner.name         : '';
                let user_email         = user && user.email ? user.email : '';
                let input_phone_number = this.state.phone_number;
                let number_with_plus   = input_phone_number;

                let phone_request_data = {
                  phone: number_with_plus,
                  email: user_email,
                  partner_name: partner_name,
                  host_name: host_name
                }

                let send_code_res = await UserController.sendRegisterPhoneCode(phone_request_data);
                let is_success    = send_code_res && send_code_res.success ? true : false;
                let error         = send_code_res && send_code_res.error   ? send_code_res.error : '';

                this.setState({ loading_send_code: false, display_code_input: is_success, error: error });
              }} />
      <Error error={this.state.error} style={{ marginTop: 15 }}/>
    </div>
  }

  render_code_input = () => {
    return <div style={{ display: 'flex', flexDirection: 'column',  }}>
      { this.render_title_description('Enter Code', "We've sent a verification code to your phone number.") }
      <div style={{ display: 'flex', flexDirection: 'column', height: 103, justifyContent: 'center' }}>
        <CodeInput value={this.state.code}
                   placeholder='000000'
                   hide_label={true}
                   style={{ borderRadius: 12, height: 55 }}
                   onChange={ (text_input) => {
                     if (text_input.length === 6) {
                       this.verify_code_action(text_input);
                     }
                     this.setState({ code: text_input })
                   }}/>
      </div>
      <Button title='Verify Code'
              disabled={this.state.send_code_disabled}
              loading={this.state.loading_verify_code}
              style={{ height: 55, marginTop: 20, marginRight: 10, marginLeft: 10, width: 300, alignSelf: 'center', boxShadow: 'none' }}
              titleStyle={{ fontSize: 16 }}
              onClick={ () => {
                this.verify_code_action(this.state.code)
              }} />

      <Button title={'Resend Code'}
              grey={true}
              style={{ marginTop: 10, alignSelf: 'center', height: 35, paddingLeft: 25, paddingRight: 25 }}
              titleStyle={{ fontSize: 12 }}
              onClick={ () => {
                this.resend_code_action();
              }}/>
    </div>
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        { !this.state.display_code_input ? this.render_phone_input() : null }
        {  this.state.display_code_input ? this.render_code_input()  : null }
      </div>
    );
  }

  verify_code_action = async (full_code) => {
    this.setState({ loading_verify_code: true });

    let code = full_code;
    let request_data    = { phone_number: this.state.phone_number, code: code }
    let verify_code_res = await UserController.validatePhoneAndUpdate(request_data);
    let is_success      = verify_code_res && verify_code_res.success ? true : false;
    let user_details    = await AuthController.getLoggedInUser();

    if (is_success && this.props.success_action) {
      this.props.success_action();
    }

    this.setState({ loading_verify_code: false });
  }

  resend_code_action = () => {
    this.setState({
      code: '',
      phone_number: '',
      send_code_disabled: false,
      loading_send_code: false,
      loading_verify_code: false,
      display_code_input: false
    })
  }
}
